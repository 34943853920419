import img from "./img/2waylogo.gif";
import HeaderFooterWraper from "./helpers/HeaderFooterWrapper";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderFooterWraper>
        <section className="hero has-2wayview-bg columns is-vcentered">
          <div className="hero-body column is-12 is-vcentered">
            <p className="privacy-term-title has-text-weight-bold has-text-centered is-size-3">
              PRIVACY POLICY
            </p>
          </div>
        </section>
        <div className="container ">
          <div className="content p-5 has-text-left">
            <div className="block has-text-white">
              This policy is effective as of the date that you access our
              website.
            </div>
            <div className="block has-text-white">
              BY USING OUR SERVICES, YOU GIVE YOUR CONSENT THAT ALL PERSONALLY
              IDENTIFIABLE INFORMATION THAT YOU SUBMIT OR THAT IS COLLECTED
              THROUGH THE SERVICES, NAMELY YOUR E-MAIL ADDRESS AND PERSONAL
              CONTACT DETAILS, MAY BE PROCESSED BY IJMCM LLC IN THE MANNER AND
              FOR THE PURPOSES DESCRIBED IN THIS PRIVACY POLICY.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                1. Collected Data
              </strong>
            </div>
            <div className="block has-text-white">
              Some features of the Services do not require any personal
              information to be submitted. In order to use other features and
              fully access all of what our Services have to offer you we may
              collect the following types of personal information or data:
            </div>
            <div className="block has-text-white">Data you may provide us:</div>
            <div className="block has-text-white">
              We may also use the information you provided us to contact your
              from time to time to provide you with important information,
              required notices and marketing promotions.
            </div>
            <div className="block has-text-white">
              -- When you sign up for the Services, we may collect your
              username, password and email address. This data helps us identify
              you and provide the Services for you, and also to inform you about
              your account and the Services.
              <br /> -- If you choose to, you can also give us additional
              information, such as your real name, your role (parent, student,
              teacher etc.), and your phone number, in order to make your
              experience better.
              <br /> -- We also collect content you provide to us when you use
              our Services, such as information regarding your use of the
              Services, sample audio recordings for quality assurance and
              improving acoustic recognition experience, and other information
              relevant to customer surveys and/or commercial offers.
              <br /> -- If you contact with our support team regarding issues in
              our Services or any other issues, we may collect the information
              regarding your interactions with us, in order to help us provide a
              better service.
              <br /> -- We also store all messages sent to our forums to help
              users find useful information about the Services.
              <br /> -- Your payment data is required to process the billing of
              your Services subscriptions and other purchases you make.
            </div>

            <div className="block has-text-white">
              Data we may collect automatically:
            </div>

            <div className="block has-text-white">
              -- We may automatically receive and record information from your
              device and browser, including your cookie information, your
              country, regional and language settings, device model, operating
              system, mobile carrier and software and hardware attributes.
              <br /> -- We may use your IP address to provide you with our
              product on your device, and to help us with server problem
              diagnosis and to administer the Services. An IP address is a
              numeric code that identifies your device on a network, or in this
              case, the Internet.
              <br /> -- We may also need to use your IP geolocation data in
              order to receive your general location and provide you with
              contents adjusted for your territory. Your IP address is also used
              to gather broad demographic information.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                2. Why We Collect Your Data
              </strong>
            </div>
            <div className="block has-text-white">
              We collect and process your personal data mainly for making the
              Services work and, to better understand your needs and to improve
              the Services offered to you.
            </div>
            <div className="block has-text-white">
              In addition to instances where we received consent from you
              (notably to communicate with you), it may be necessary for us to
              collect your data to comply with a legal obligation (if any) or
              for legitimate purposes in the interest of IJMCM LLC, to develop
              new services and products, to improve customer services and as
              further detailed below.
            </div>
            <div className="block has-text-white">
              More specifically, your data will be used for: setting-up your
              account, operate the Services and allow you to use them as well as
              to manage our customer relationship with you; process your
              transactions; provide you with the services and products that you
              request and provide you with Service-related communications;
              sending updates about new products, special offers or other
              information which we think you may find interesting using the
              e-mail address which you have provided.
            </div>
            <div className="block has-text-white">
              We may also collect your personal data in order to improve our
              Services through internal record keeping and statistical analysis;
              monitor the effectiveness of our content; usage statistics;
              understand user preferences; improve our promotional activity;
              create new services and features; and develop new products and
              services.
            </div>
            <div className="block has-text-white">
              We may analyze, profile and segment all such collected data.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                3. When and How Do We Share Your Data With Third Parties?
              </strong>
            </div>
            <div className="block has-text-white">
              IJMCM LLCdoes not share any personal identifiable information or
              data with third parties. However, IJMCM LLC may use third parties
              to facilitate its business, such as payment processors, hosting
              services providers, contractors for sending out e-mail updates
              about our Services or for providing customer support services. In
              connection with these business operations, our contractors and
              service providers may have access to your personal data collected
              we collect for use for a limited time in connection with these
              business activities.
            </div>
            <div className="block has-text-white">
              We may disclose information, inter alia, for the following
              reasons:
              <br />
              (a) if we are required to do so by law;
              <br />
              (b) if we are required to do so in a legal process;
              <br />
              (c) if we feel that disclosing information might prevent a crime;
              <br />
              (d) in order to protect the rights or property of IJMCM LLC
              including enforcing the Terms of Use.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                4. Third Party Services and Platforms
              </strong>
            </div>
            <div className="block has-text-white">
              Our website may contain links to third party websites. Such third
              parties may access and collect some of your data. This Privacy
              Policy only applies only to our Services and information we
              collect, while such third parties operate under their own privacy
              policies.
            </div>
            <div className="block has-text-white">
              IJMCM LLCmay retain third parties service providers to perform
              services as aforesaid, such as database management, maintenance,
              marketing, data processing and analytics. These third parties have
              access to your data only to carry out these tasks on our behalf.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Data Analysis Services
              </strong>
            </div>
            <div className="block has-text-white">
              We may use services such as (but not limited to) Google Analytics
              to understand users' behavior. These tools may use cookies to
              collect information. We use this information only to manage and
              improve our Services, and to create new features and
              functionality.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                5. Controlling Your Privacy Rights
              </strong>
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                1. Location
              </strong>
              <br />
              Personal data is processed at our operating offices and in any
              other places where the parties involved in the processing are
              located. Depending on your location, data transfers may involve
              transferring your personal data to a country other than your own.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                2. Retention
              </strong>
              <br />
              Personal data shall be processed and retained for as long as
              required by the purposes it has been collected for.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">3. </strong>
              <br />If you reside in the European Economic Area, you benefit from
              certain rights under the General Data Protection Regulation (EU)
              2016/679. While some of these rights apply generally, certain
              rights apply only in certain limited cases. We describe these
              rights below in a summary format, but mandated applicable law
              shall govern.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                4. Access and Porting
              </strong>
              <br />You can access much of your information by logging into your
              account. Where legally required, we can provide your information
              upon your request. Note that, in accordance with applicable law,
              information will not be provided where doing so would adversely
              affect the rights (including the intellectual property rights) of
              others.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                5. Change, Restrict, Limit
              </strong>
              <br />If you believe that any information we are holding on you is
              incorrect or incomplete, please e-mail us as soon as possible,
              at <text className="block has-text-black">admin@ijmcm.com</text> We will promptly correct any information found
              to be incorrect.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                6. Delete
              </strong>
              <br />You have the right to end this relationship is by deleting your
              account. If you choose to do so, we will delete all of your
              personal data from our systems, including backups within 30 days,
              unless there is a legitimate business interest to keep the data,
              such as to comply with legal obligations, to enforce agreements or
              to resolve disputes.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                7. Forgotten
              </strong>
              <br />You have the right to be forgotten. If you choose to delete your
              profile, we will delete all associated information and it will be
              like you never signed up in the first place (with the exceptions
              mentioned above, meaning that we may, for a legitimate reason,
              have the right to keep some of your data).
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                8. Object
              </strong>
              <br />If we process your information based on our legitimate interests
              explained above, or in the public interest, you can object to this
              processing in certain circumstances. In such cases, we will cease
              processing your information unless we have compelling legitimate
              grounds to continue processing or where it is needed for legal
              reasons.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                9. Revoke consent
              </strong>
              <br />Where you have previously provided your consent, you have the
              right to withdraw your consent to the processing of your
              information at any time. In certain cases, we may continue to
              process your information after you have withdrawn consent if we
              have a legal basis to do so or if your withdrawal of consent was
              limited to certain processing activities.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-6">
                10. Complain
              </strong>
              <br />Should you wish to raise a concern about our use of your
              information (and without prejudice to any other rights you may
              have), you have the right to do so with your local data protection
              authority.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                Security
              </strong>
            </div>
            <div className="block has-text-white">
            We maintain technical and organizational measures to protect against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to your information and we are committed to ensuring that all of your collected data is secure.
</div>
            <div className="block has-text-white">We strive to maintain the reliability, accuracy and completeness of personal information that we collect and to protect the privacy and security of our users. We keep your personal information only for as long as reasonably necessary for the purposes for which it was collected or to comply with any applicable legal or ethical reporting or document retention requirements.
</div>
            <div className="block has-text-white">We limit access to personal information about you to employees, contractors and service provider who we believe reasonably need to come into contact with that personal information. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations.
</div>
            <div className="block has-text-white">If you suspect your login information has been compromised, you should notify us immediately at <text className="block has-text-black">admin@ijmcm.com</text> and change your password.
</div>
            <div className="block has-text-white">From time to time, we review our security mechanisms. Be aware though that, despite the efforts and resources we are investing, no security measures are perfect or impenetrable. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                7. Age Limit
              </strong>
            </div>
            <div className="block has-text-white">
            We care about the safety of children. Therefore, the Services are not directed towards children under the age of 13, they are not allowed to register with or use the Services, or to disclose any personal identifiable information without appropriate parental or legal guardian approval.
</div>
            <div className="block has-text-white">If a child is under the age of 13, you must obtain parental consent (or consent from a legal guardian) prior to using our Services. We do not knowingly contact or engage with children under the age of 13 without said parental consent (or consent from a legal guardian).
</div>
            <div className="block has-text-white">If you have reason to believe that a child under the said age has provided us with their personal information, please contact us at <text className="block has-text-black">admin@ijmcm.com</text>, and we will endeavor to delete that personal information from our databases
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                8. Note for California Residents
              </strong>
            </div>
            <div className="block has-text-white">
            If you are a California resident, please note that under the California Consumer Privacy Act of 2018, as amended ("CCPA"), and regulations promulgated thereunder, you are entitled to receive specific information on how we process your data. You also have specific rights to your data, including the right to opt out of the sale of your personal information. Please review our<a  href="https://www.joytunes.com/ccpa" target='_blank'> Supplementary Privacy Notice for California Residents</a> to familiarize yourself with your rights under the CCPA.
            </div>

            <div className="block has-text-white">
              <strong className="block has-text-white is-size-5">
                9. Questions
              </strong>
            </div>
            <div className="block has-text-white">
            If you have questions or wish to send us comments about this Privacy Policy, please send an e-mail with your questions or comments to <text className="block has-text-black">admin@ijmcm.com</text>
We require that you read this Privacy Policy and encourage you to raise any questions with us. IJMCM LLCmay change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.

            </div>
          </div>
        </div>
      </HeaderFooterWraper>
    </>
  );
};
export default PrivacyPolicy;
