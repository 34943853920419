/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import config from "../../utils/config";
import CorrectModal_01 from "../helpers/CorrectModal_01";
import IncorrectModal_01 from "../helpers/IncorrectModal01";
import PopUpFinal from "../helpers/PopUpFinal";
import PopUpTheEnd from "../helpers/PopUpTheEnd";
import Spinner from "../helpers/Spinner";
import imgCynthia from "../img/Cynthiaicon.png";
import imgPresenter from "../img/Presentericon.png";
import imgSally from "../img/Sallyicon.png";
import imgSam from "../img/Samicon.png";
import DashboardFooter from "../helpers/DashboardFooter";
import PopulateSurvey from "./PopulateSurvey";
import FeedbackModal from '../helpers/FeedbackModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBackward, faForward, faRedo } from '@fortawesome/free-solid-svg-icons';

const getGameUrl = config.getGameById;
const base = config.base;
const right_you_got_it = config.right_you_got_it;
const Sam = config.why_did_you_choose_SAM_here;
const Sally = config.why_did_you_choose_SALLY_here;
const CYnthia = config.why_did_you_choose_CYNTHIA_here;
const SaveGame = config.saveGame;
const FeedbackURL = config.giveFeedback;

const GameInterface = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [data, setData] = useState();
  const [exercises, setExercises] = useState();
  const [activeExercise, setActiveExercise] = useState();
  const [exerciseItem, setExerciseItem] = useState();
  const [mainExercise, setMainExercise] = useState();
  const [isChrSelected, setSelectedChr] = useState();
  const [isIncorrect, setIncorrect] = useState(false);
  const [isIncorrectAgain, setIncorrectAgain] = useState(false);
  const [inCorrectFlag, setIncorrectAgainFlag] = useState(false);
  //const [isCorrectAnswerModalOpen, setCorrectAnswerModalOpen] = useState(false);
  const [subExcVideoArr, setSubExcVideoArr] = useState();
  const [chrName, setChrName] = useState();
  const [isPlaying, setPlaying] = useState(false);
  const [isRepeat, setRepeatItem] = useState();
  const [repeatCount, setRepeatCount] = useState(0);
  const [subAnswer, setSubAnswer] = useState();
  const [isAnswerCorrect, setAnswerCorrect] = useState(false);

  const [timer, setTimer] = useState(10);
  const [isTimerActive, setTimerIsActive] = useState(false);
  const [isTimerPaused, setTimerIsPaused] = useState(false);

  const [totalExercise, setTotalExercise] = useState();
  const [isPopUpActive, setPopUpActive] = useState(false);
  const [isPopUpWrongActive, setPopUpWrongActive] = useState(false);
  const [newSession, setNewSession] = useState(false);
  const [scoreMain, setScoreMain] = useState([]);
  const timerIncrement = useRef(null);
  const [isResume, setResume] = useState(false);
  const [isCycleComplete, setCycleComplete] = useState(true);
  const [loopCompleted, setLoopCompleted] = useState(false);
  const [isFinalActive, setFinalActive] = useState(false);
  const [isTheEnd, setTheEnd] = useState(false);
  const [isDropDwonActive, setDropDownActive] = useState(false);

  //feedback
  const [isFeedbackPopUpActive, setFeedbackPopUpActive] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isShowMessage, setShowMessage] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false)


  const state = location.state || {};

  //game id for only games of falls
  const gameID = "1"; //adding hard coded game id as system needs to show only one game
  //navigate.location.state.GameId;
  let gameUrlById = getGameUrl + gameID;

  useEffect(() => {
    setCycleComplete(true);
    setSelectedChr();
    fetchGame();
    handleStart();
  }, []);

  const showMessage = (response) => {

    if (isSuccess) {
      return (
        <div className="message is-success">
          <div className="message-body">{response}</div>
        </div>
      )
    } else if (isError) {
      return (
        <div className="message is-danger">
          <div className="message-body">{response}</div>
        </div>
      )
    }

  }

  const handleFeedbackSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    axios({
      method: "POST",
      url: FeedbackURL,
      data: { feedback: feedback, email: user.email, submittedOn: new Date(Date.now()) }
    }).then((response) => {
      if (response.status === 200) {
        setLoading(false)
        // console.log(response.data.message)
        setShowMessage(response.data.message)
        setSuccess(true)
        showMessage(response.data.message)
        setFeedbackPopUpActive(false)
        setFeedback("");
        setPlaying(true);
        return

      } else if (response.status !== 201) {
        setLoading(false)
        // console.log(response.data.message);
        setShowMessage(response.data.message)
        setError(true)
        showMessage(response.data.message)
        setFeedbackPopUpActive(false)
        setFeedback("");
        setPlaying(true);
      }
    }).catch((error) => {
      // console.log(error);
      setLoading(false)
      setShowMessage(error.message)
      setError(true)
      showMessage(error.message);
      setFeedbackPopUpActive(false)
      setFeedback("");
      setPlaying(true);
    })

  }

  const onFeedbackInput = (event) => {
    setFeedback(event.target.value);
  }

  // timer start
  const handleStart = () => {
    setTimerIsActive(true);
    setTimerIsPaused(true);
    timerIncrement.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };
  const handleReset = () => {
    clearInterval(timerIncrement.current);
    setTimerIsActive(false);
    setTimerIsPaused(false);
    setTimer(0);
  };
  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  //Timer End
  const matchImageWithName = (name) => {
    let chr;
    let imgPath;
    if (name.includes("#" || " ")) {
      var results = name.split("#");
      chr = results[0];
    } else if (name.includes(" ")) {
      var results = name.split(" ");
      chr = results[0];
    } else {
      chr = name;
    }
    // eslint-disable-next-line default-case
    switch (chr.trim()) {
      case "Sam":
        imgPath = imgSam;
        break;
      case "Sally":
        imgPath = imgSally;
        break;
      case "Cynthia":
        imgPath = imgCynthia;
        break;
      case "Presenter":
        imgPath = imgPresenter;
        break;
    }
    return imgPath;
  };

  const handleAnswerSubmit = () => {
    const time = new Date();
    if (isChrSelected) {
      let correctAnswer = exerciseItem.main.answer;
      let selectedAnswer = isChrSelected;
      if (correctAnswer === selectedAnswer) {
        setScoreMain((prevState) => [
          ...prevState,
          { attempted_at: time, attempt_outcome: "correct" },
        ]);
        setAnswerCorrect(true);
        setPopUpWrongActive(true);
        setPlaying(false);
      } else if (inCorrectFlag === true) {
        setScoreMain((prevState) => [
          ...prevState,
          { attempted_at: time, attempt_outcome: "incorrect" },
        ]);
        setPlaying(false);
        setIncorrectAgain(true);
        setPopUpActive(true);
      } else {
        setScoreMain((prevState) => [
          ...prevState,
          { attempted_at: time, attempt_outcome: "incorrect" },
        ]);
        setPlaying(false);
        setIncorrect(true);
        setIncorrectAgainFlag(true);
        setPopUpActive(true);
      }
    }
  };
  const onEndedIncorrectModal_01 = () => {
    setPopUpActive(false);
    setIncorrect(true);
    setPlaying(true);

    //setPlaying(true)
  };
  const onEndedIncorrectModal_02 = () => {
    setPopUpActive(true);
    setIncorrectAgain(false);
  };

  const onCloseModal_02 = () => {
    setIncorrectAgain(false);
    setPlaying(true);
  };
  const onCloseModal_01 = () => {
    setIncorrect(false);
    setPlaying(true);
  };

  const onRepeat = () => {
    setRepeatCount(repeatCount + 1);
    setSelectedChr();
    if (isPopUpActive === true) {
      setPopUpActive(false);
    }

    onCharacterClick(isRepeat);
    setPlaying(true);
  };

  const OnSkip = () => {
    setSelectedChr();
    setNewSession(false);
    if (isPopUpActive === true) {
      setPopUpActive(false);
    }
    let excLength = exercises.length;
    scoreSkipSave(activeExercise);
    if (activeExercise <= excLength) {
      let nexExc = activeExercise + 1;
      exercises &&
        exercises.map((item) => {
          if (item.exercise_id === nexExc) {
            handleExercise(item);
            handleReset();
            handleStart();
          }
        });
    }
    setPlaying(false);
    if (activeExercise === excLength) {
      setPlaying(false);
      setTimeout(() => {
        setFinalActive(true);
        // props.navigate({
        //   pathname: '/dashboard',
        //   state: 'game'
        // })
      }, 100);
    }
  };

  const showResultsRedirect = () => {
    setCycleComplete(true);
    setSelectedChr();
    saveScore();
    if (isPopUpWrongActive === true) {
      setPopUpWrongActive(false);
      setIncorrectAgain(false);
    }
    if (isTheEnd === true) {
      setTheEnd(false);
      exercises &&
        exercises.map((item) => {
          if (item.exercise_id === 1) {
            handleExercise(item);
            handleReset();
            handleStart();
          }
        });
    }
    let excLength = exercises.length;
    if (activeExercise <= excLength) {
      let nexExc = activeExercise + 1;
      exercises &&
        exercises.map((item) => {
          if (item.exercise_id === nexExc) {
            handleExercise(item);
            handleReset();
          }
        });
    }
    setPlaying(false);
    setTimeout(() => {
      // setFinalActive(true)
      navigate('/dashboard', { state: { type: "Reports" } });

    }, 1000);
  };

  const nextExercise = () => {
    setCycleComplete(true);
    setNewSession(false);
    setSelectedChr();
    saveScore();
    if (isPopUpWrongActive === true) {
      setPopUpWrongActive(false);
      setIncorrectAgain(false);
    }
    if (isTheEnd === true) {
      setTheEnd(false);
      exercises &&
        exercises.map((item) => {
          if (item.exercise_id === 1) {
            handleExercise(item);
            handleReset();
            handleStart();
          }
        });
    }
    let excLength = exercises.length;
    if (activeExercise <= excLength) {
      let nexExc = activeExercise + 1;
      exercises &&
        exercises.map((item) => {
          if (item.exercise_id === nexExc) {
            handleExercise(item);
            handleReset();
            handleStart();
          }
        });
    }
    setPlaying(false);
    if (activeExercise === excLength) {
      setPlaying(false);
      setTimeout(() => {
        setFinalActive(true);
        navigate('/dashboard', { state: { type: "game" } });
      }, 100);
    }
  };
  const changeGamePart = (excNo) => {
    let jumpTo = excNo;
    if (jumpTo > activeExercise) {
      let distance = jumpTo - activeExercise;
      let destination = activeExercise + distance;
      exercises &&
        exercises.map((item) => {
          if (item.exercise_id === destination) {
            handleExercise(item);
            handleReset();
            handleStart();
          }
        });
    }
    if (jumpTo < activeExercise) {
      let distance = activeExercise - jumpTo;
      let destination = activeExercise - distance;
      exercises &&
        exercises.map((item) => {
          if (item.exercise_id === destination) {
            handleExercise(item);
            handleReset();
            handleStart();
          }
        });
    }
  };
  const prevExercise = () => {
    setSelectedChr();
    setCycleComplete(true);
    if (activeExercise > 1) {
      let prevExc = activeExercise - 1;
      exercises &&
        exercises.map((item) => {
          if (item.exercise_id === prevExc) {
            handleExercise(item);
            handleReset();
            handleStart();
          }
        });
    }
    setPlaying(false);
    if (activeExercise === 1) {
      setSaving(true);
      setTimeout(() => {
        setSaving(false);
        navigate('/dashboard', { state: { type: "Reports" } });
      }, 1500);
    }
  };
  const onSaveAndExit = () => {
    setSaving(true);
    //scoreSkipSave();
    saveScore();
    if (activeExercise === 50) {
      setPlaying(false);
      setTimeout(() => {
        setFinalActive(true);
        // navigate('/dashboard', { state: "game" });
      }, 100);
    } else
      setTimeout(() => {
        setSaving(false);
        navigate('/dashboard', { state: { type: "game" } });
      }, 1500);
  };

  const scoreSkipSave = () => {
    let time = new Date();
    let skippedMainScore = [
      { attempted_at: time, attempt_outcome: "incorrect" },
    ];
    var config = {
      method: "post",
      url: SaveGame,
      headers: { Authorization: "Bearer " + token },
      data: {
        gameID: gameID,
        exercise: {
          repeats: repeatCount,
          main: skippedMainScore,
          exercise_id: activeExercise,
          skip: true,
          time: timer,
        },
        newSession: newSession === undefined ? false : newSession,
      },
    };
    axios(config)
      .then(function (response) {
        setScoreMain([]);
        setRepeatCount(0);
        // console.log(response.data, "scoreSkipSave response");
        //save silently
      })
      .catch(function (error) {
        // fail silently
      });
  };

  const saveScore = () => {
    var config = {
      method: "post",
      url: SaveGame,
      headers: { Authorization: "Bearer " + token },
      data: {
        gameID: gameID,
        exercise: {
          repeats: repeatCount,
          exercise_id: activeExercise,
          main: scoreMain,
          skip: false,
          time: timer,
        },
        newSession: newSession === undefined ? false : newSession,
      },
    };
    axios(config)
      .then(function (response) {
        setScoreMain([]);
        setRepeatCount(0);
        // console.log(response.data, "scoreSkipSave response");
        //save silently
      })
      .catch(function (error) {
        // fail silently
      });
  };

  const handleExercise = (item) => {
    setCycleComplete(true);
    setSelectedChr();
    let answer = item.main.answer;
    setIncorrectAgainFlag(false);
    let exerciseId = item.exercise_id;
    setActiveExercise(exerciseId);
    setExerciseItem(item);
    onCharacterClick(item.main.options[0]);
    setRepeatItem(item.main.options[0]);

    handleReset();
    handleStart();
  };

  const fetchGame = () => {
    setNewSession(state.newSession);
    setLoading(false);
    var config = {
      method: "get",
      url: gameUrlById,
      headers: { Authorization: "Bearer " + token },
    };
    axios(config)
      .then(function (response) {
        setData(response.data);
        setExercises(response.data.exercises);
        setTotalExercise(response.data.exercises.length);
        if (state.resume === true) {
          let itemId = state.lastExercise === 50 ? 1 : state.lastExercise + 1;
          // console.log('item id from use effecr', exercises && exercises)
          response.data.exercises.map((item) => {
            // console.log('item loops', item)
            if (item.exercise_id === itemId) {
              handleExercise(item);
            }
          });
          state.resume = false;
        } else {
          handleExercise(response.data.exercises[0]);
        }

        //setLoading(false)
      })
      .catch(function (error) {
        //setLoading(false)
      });
  };
  const onCharacterClick = (item) => {
    setMainExercise(item);
  };

  const onFinalEnded = () => {
    setFinalActive(false);
    setTheEnd(true);
  };

  const onEndedCorrectModal_01 = () => {
    if (activeExercise === 50) {
      setAnswerCorrect(false);
      setPopUpWrongActive(false);
      setFinalActive(true);
      return;
    }
    setAnswerCorrect(false);
    setPopUpWrongActive(false);
    nextExercise();
  };
  const gamePartOnDropDown = (id) => {
    if (id >= 1 && id <= 3) {
      return (
        <>
          <div className="dropdown-item"> PART 1:RECOGNIZING HAZARDS </div>
        </>
      );
    }
    if (id >= 4 && id <= 10) {
      return (
        <>
          <div className="subtitle text-blue has-text-weight-bold">
            {" "}
            PART 2:RECOGNIZING SIGNALS{" "}
          </div>
        </>
      );
    }
    if (id >= 11 && id <= 22) {
      return (
        <>
          <div className="subtitle text-blue has-text-weight-bold">
            {" "}
            PART 3:OTHER TYPES OF SIGNALS
          </div>
        </>
      );
    }
    if (id >= 23 && id <= 26) {
      return (
        <>
          <div className="subtitle text-blue has-text-weight-bold">
            {" "}
            PART 4:UNDERSTANDING UNSEEN SIGNALS{" "}
          </div>
        </>
      );
    }
    if (id >= 27 && id <= 31) {
      return (
        <>
          <div className="subtitle text-blue has-text-weight-bold">
            {" "}
            PART 5:PRACTICING WORKING WITH SIGNALS AND REMINDERS
          </div>
        </>
      );
    }
    if (id >= 32 && id <= 35) {
      return (
        <>
          <div className="subtitle text-blue has-text-weight-bold">
            {" "}
            PART 6:MORE HOME-BASED PRACTICE
          </div>
        </>
      );
    }
    if (id >= 36 && id <= 41) {
      return (
        <>
          <div className="subtitle text-blue has-text-weight-bold">
            {" "}
            PART 7:PERSONAL ITEMS{" "}
          </div>
        </>
      );
    }
    if (id >= 42 && id <= 50) {
      return (
        <>
          <div className="subtitle text-blue has-text-weight-bold">
            {" "}
            PART 8:SUMMARY AND REVIEW
          </div>
        </>
      );
    }
  };

  const gamePartsName = () => {
    let id = activeExercise;
    if (id >= 1 && id <= 3) {
      return (
        <>
          <button
            style={{height: "100%"}}
            className="button is-info is-small  is-previous is-vcentered"
            disabled
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
            </span>
          </button>
          <p className="card-header-title text-black" style={{ display: "block" }}>
            PART 1:RECOGNIZING HAZARDS{" "}
          </p>
          <button
            style={{height: "100%"}}
            className="button is-small is-info is-next"
            onClick={() => changeGamePart(4)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>
            </span>
          </button>
        </>
      );
    }
    if (id >= 4 && id <= 10) {
      return (
        <>
          <button
          style={{height: "100%"}}
            className="button is-small is-info is-previous "
            onClick={() => changeGamePart(1)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
            </span>
          </button>
          <p className="card-header-title  text-black" style={{ display: "block" }}>
            PART 2:RECOGNIZING SIGNALS{" "}
          </p>
          <button
          style={{height: "100%"}}
            className="button is-small is-info is-next"
            onClick={() => changeGamePart(11)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>
            </span>
          </button>
        </>
      );
    }
    if (id >= 11 && id <= 22) {
      return (
        <>
          <button
          style={{height: "100%"}}
            className="button is-small is-info is-previous "
            onClick={() => changeGamePart(4)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
            </span>
          </button>
          <p className="card-header-title  text-black" style={{ display: "block" }}>
            PART 3:OTHER TYPES OF SIGNALS
          </p>
          <button
          style={{height: "100%"}}
            className="button is-small is-info is-next"
            onClick={() => changeGamePart(23)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>
            </span>
          </button>
        </>
      );
    }
    if (id >= 23 && id <= 26) {
      return (
        <>
          <button
          style={{height: "100%"}}
            className="button is-small is-previous is-info"
            onClick={() => changeGamePart(11)}
          >
            <span className="icon is-small">
            <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
            </span>
          </button>
          <p className="card-header-title  text-black" style={{ display: "block" }}>
            PART 4:UNDERSTANDING UNSEEN SIGNALS
          </p>
          <button
          style={{height: "100%"}}
            className="button is-small is-info is-next"
            onClick={() => changeGamePart(27)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>            
            </span>
          </button>
        </>
      );
    }
    if (id >= 27 && id <= 31) {
      return (
        <>
          <button
          style={{height: "100%"}}
            className="button is-small is-previous is-info"
            onClick={() => changeGamePart(23)}
          >
            <span className="icon is-small  is-info">
            <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
            </span>
          </button>
          <p className="card-header-title  text-black" style={{ display: "block" }}>
            PART 5:PRACTICING WORKING WITH SIGNALS AND REMINDERS
          </p>
          <button
          style={{height: "100%"}}
            className="button is-small is-info is-next"
            onClick={() => changeGamePart(32)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>            
            </span>
          </button>
        </>
      );
    }
    if (id >= 32 && id <= 35) {
      return (
        <>
          <button
          style={{height: "100%"}}
            className="button is-small is-info is-previous "
            onClick={() => changeGamePart(27)}
          >
            <span className="icon is-small">
            <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
            </span>
          </button>
          <p className="card-header-title text-black " style={{ display: "block" }}>
            PART 6:MORE HOME-BASED PRACTICE
          </p>
          <button
            style={{height: "100%"}}
            className="button is-small is-info is-next"
            onClick={() => changeGamePart(36)}
          >
            <span className="icon is-small">
            <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>
            </span>
          </button>
        </>
      );
    }
    if (id >= 36 && id <= 41) {
      return (
        <>
          <button
            style={{height: "100%"}}
            className="button is-info is-small is-previous "
            onClick={() => changeGamePart(32)}
          >
            <span className="icon is-small">
            <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
            </span>
          </button>
          <p className="card-header-title  text-black" style={{ display: "block" }}>
            PART 7:PERSONAL ITEMS
          </p>
          <button
            style={{height: "100%"}}
            className="button is-info is-small is-next"
            onClick={() => changeGamePart(42)}
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>            
            </span>
          </button>
        </>
      );
    }
    if (id >= 42 && id <= 50) {
      return (
        <>
          <button
          style={{height: "100%"}}
            className="button is-small is-previous is-info"
            onClick={() => changeGamePart(36)}
          >
            <span className="icon is-info is-small">
            <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
            </span>
          </button>
          <p className="card-header-title text-black " style={{ display: "block" }}>
            PART 8:SUMMARY AND REVIEW
          </p>
          <button style={{height: "100%"}} className="button is-small is-info is-next" disabled>
            <span className="icon is-small">
            <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>
            </span>
          </button>
        </>
      );
    }
  };
  const exercisesMapList = (startId, endID) => {
    return (
      exercises &&
      exercises.map((item) => {
        if (startId <= item.exercise_id && item.exercise_id <= endID) {
          return (
            <>
              <a
                className={`dropdown-item exercise-item text-black ${activeExercise === item.exercise_id ? "is-active" : ""
                  }`}
                onClick={() => handleExercise(item)}
              >
                {" "}
                Exercise : {item.exercise_id}
              </a>
            </>
          );
        }
      })
    );
  };

  const onEndMainVideo = () => {
    let id = mainExercise.id;
    // eslint-disable-next-line default-case
    switch (id) {
      case 1:
        if (!loopCompleted) {
          setMainExercise(exerciseItem.main.options[1]);
        }
        break;
      case 2:
        if (!loopCompleted) {
          setMainExercise(exerciseItem.main.options[2]);
        }
        break;
      case 3:
        if (!loopCompleted) {
          setMainExercise(exerciseItem.main.options[3]);
          setLoopCompleted(false); // changed to false so video auto play
          setCycleComplete(false);
        }
        break;
    }
  };

  const charactersMappedComponent = () => {
    var imgArr;
    //['',imgPresenter, imgSam, imgSally, imgCynthia]
    return (
      exerciseItem &&
      exerciseItem.main.options.map((item, index) => {
        imgArr = matchImageWithName(item.character);

        return (
          <div className="">
            <div className="is-hidden-mobile column">
              <button
                style={{ paddingLeft: '25px', paddingRight: '25px', paddingBottom: '5px', border: '1px solid darkgray'}}
                className={`rounded is-ghost text-black white-bg is-active  ${mainExercise && mainExercise.character === item.character
                  ? "blue-bg  "
                  : " "
                  } ${isCycleComplete == false ? " " : " "}}  `}
                onClick={() => onCharacterClick(item)}
              >
                <figure 
                style={{margin: '5px'}}
                className="image is-48x48">
                  <img className="has-text-centered is-rounded" src={imgArr} />
                </figure>
                {item.character}
              </button>
            </div>
            <button
              className={`charecter-mbl white-bg button pr-2 pl-3 custom-invisiable-style-wider  is-hidden-tablet-only is-hidden-desktop-only is-active is-hidden-widescreen-only ${mainExercise && mainExercise.id == item.id
                ? "blue-bg"
                : ""
                }`}
              onClick={() => onCharacterClick(item)}
            >
              <span className="icon is-large">
                <img src={imgArr} />
              </span>
              <span>{item.character}</span>
            </button>
          </div>
        );
      })
    );
  };

  const mainVideoInterface = () => {
    return (
      <>
        <ReactPlayer
          onReady={() => {
            setLoading(false);
          }}
          playing={isPlaying}
          url={mainExercise && base + mainExercise.video_url}
          controls={true}
          onEnded={onEndMainVideo}
          width="100%"
          height="100%"
          className="has-frame-border"
          pip={false}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
        />
        <p className="helper has-text-centered has-text-weight-bold text-blue mb-2 ">
          {mainExercise && mainExercise.character}
        </p>
      </>
    );
  };

  const answerOptions = () => {
    //var imgArr = ['',imgPresenter, imgSam, imgSally, imgCynthia]
    let imgArr2;
    return (
      exerciseItem &&
      exerciseItem.main.options.map((item) => {
        imgArr2 = matchImageWithName(item.character);

        if (item.is_selectable === true && item.id !== 1 && item.id !== 4) {
          return (
            <button
              className={`answer-btn button text-black white-bg pr-3 pl-3 ${isChrSelected === item.id ? "selected-answer" : ""
                }`}
              onClick={() => {
                setSelectedChr(item.id);
              }}
            >
              {/* <span className="icon is-large mx-2">
                <img src={imgArr2} />
              </span> */}
              <figure className="image is-48x48 mr-1 ml-0">
                <img className="has-text-centered is-rounded" src={imgArr2} />
              </figure>
              {item.character}
            </button>
          );
        }
      })
    );
  };

  const whoIsCorrect = () => {
    return (
      <div className="card column is-12 white-bg box-shadow rounded">
        <div className="card-header has-text-centered">
          <p style={{ display: "block" }} className="card-header-title  text-black">
            Who is Correct?
          </p>
        </div>
        <div className="card-content">
          <div className="content">
            <div className="buttons px-0 is-centered are-large">
              {answerOptions()}
            </div>
          </div>
        </div>
        <footer style={{borderColor: '#EDEDED'}} className="card-footer p-4 p-1-is-mobile">
          <a
            className="card-footer-item blue-bg button is-primary"
            disabled={isChrSelected ? false : true}
            onClick={handleAnswerSubmit}
          >
            Okay
          </a>
        </footer>
      </div>
    );
  };

  const controlPanel = () => {
    return (
      <div className="card mt-16 mb-2 column is-12 white-bg box-shadow rounded">
        <header className="card-header has-text-centered">
          {gamePartsName()}
        </header>
        <div className="card-content">
          <div className="field has-addons is-justify-content-space-around">
            <p className="control">
              <button
              style={{borderColor: 'darkgray'}}
                className="button is-medium white-bg text-black"
                disabled={activeExercise === 1 ? true : false}
                onClick={prevExercise}
              >
                <span className="icon is-small">
                <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
                </span>
                <span>Previous</span>
              </button>
            </p>
            <p className="control">
              <button style={{borderColor: 'darkgray'}} className="button  is-medium white-bg text-black" onClick={onRepeat}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faRedo}></FontAwesomeIcon>
                </span>
                <span>Repeat</span>
              </button>
            </p>
            <p className="control">
              <button
              style={{borderColor: 'darkgray'}}
                className="button is-medium white-bg text-black"
                disabled={activeExercise === 50 ? true : false}
                onClick={OnSkip}
              >
                <span>Skip</span>
                <span className="icon is-small ">
                <FontAwesomeIcon icon={faForward}></FontAwesomeIcon>
                </span>
              </button>
            </p>
          </div>
          <span className="progress-has-value text-white">{`${activeExercise ? activeExercise : "0"
            }/${totalExercise ? totalExercise : "0"}`}</span>
          <progress
            className="progress is-success is-medium show-value "
            value={activeExercise}
            max={totalExercise}
          ></progress>
        </div>
        <footer style={{borderColor: '#EDEDED'}} className="card-footer p-4">
          <a
            className={`card-footer-item button is-primary ${isSaving ? "is-loading" : " "
              }  `}
            onClick={onSaveAndExit}
          >
            Save & Resume Playing Later
          </a>
        </footer>
      </div>
    );
  };

  return !user ? (
    <Navigate to="/" />
  ) : user && user.userType === "individualUser" && !user.isSubscribed ? (
    <Navigate to="/subscription" />
  ) : (
    <div
      className="is-fullheight white-bg"
      style={{
        overflow: "hidden",
        overflowY: "hidden",
        overflowX: "hidden",
        height: "auto",
      }}
    >
   

        <div style={{display: 'flex',backgroundColor: '#284993'}} className="">
    
        
          <p className="navbar-item text-white">Game of Falls</p>
          <div style={{zIndex: '999999', display: 'flex'}} className="navbar-item">
            <div
              className=" dropdown is-hoverable "
              onClick={() => {
                setDropDownActive(!isDropDwonActive);
                setLoopCompleted(false);
              }}
            >
              <div className="dropdown-trigger ">
                <button
                  className="button white-bg text-black"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                >
                  <span>
                    Exercises
                    <span className="has-text-weight-bold text-blue">
                      ({activeExercise})
                    </span>
                  </span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faAngleDown} aria-hidden="true"></FontAwesomeIcon>
                  </span>
                </button>
              </div>
              {isDropDwonActive && (
                <div
                  className="dropdown-menu"
                  id="dropdown-menu"
                  role="menu"
                  style={{ overflow: "scroll", height: "500px"}}
                >
                  <div className="dropdown-content white-bg text-black">
                    <div className="dropdown-item text-blue is-size-7">
                      RECOGNIZNG HAZARDS
                    </div>
                    {exercisesMapList(1, 3)}
                    <hr className="dropdown-divider " />
                    <div className="dropdown-item text-blue is-size-7">
                      RECOGNIZNG SIGNALS
                    </div>
                    {exercisesMapList(4, 10)}
                    <hr className="dropdown-divider" />
                    <div className="dropdown-item text-blue is-size-7">
                      OTHER TYPES OF SIGNALS{" "}
                    </div>
                    {exercisesMapList(11, 22)}
                    <hr className="dropdown-divider" />
                    <div className="dropdown-item text-blue is-size-7">
                      UNDERSTANDING UNSEEN SIGNALS
                    </div>
                    {exercisesMapList(23, 26)}
                    <hr className="dropdown-divider" />
                    <div className="dropdown-item text-blue is-size-7">
                      PRACTICING WORKING WITH SIGNALS AND REMINDERS
                    </div>
                    {exercisesMapList(27, 31)}
                    <hr className="dropdown-divider" />
                    <div className="dropdown-item text-blue is-size-7">
                      MORE HOME-BASED PRACTICE{" "}
                    </div>
                    {exercisesMapList(32, 35)}
                    <hr className="dropdown-divider" />
                    <div className="dropdown-item text-blue is-size-7">
                      PERSONAL ITEMS{" "}
                    </div>
                    {exercisesMapList(36, 41)}
                    <hr className="dropdown-divider" />
                    <div className="dropdown-item text-blue is-size-7">
                      SUMMARY AND REVIEW{" "}
                    </div>
                    {exercisesMapList(42, 50)}
                  </div>
                </div>
              )}
            </div>
            <div className="navbar-item">
              <button
                className="button is-primary is-small p-1 is-hidden-desktop	is-hidden-tablet	is-hidden-widescreen	is-hidden-fullhd"
                onClick={onSaveAndExit}
              >
                Save & Exit
              </button>
            </div>
          </div>
          </div>
        {/* </div>
      </nav> */}
      <div style={{backgroundColor: 'transparent'}} className="container">
        <div className="columns">
          <div className="column is-12-tablet is-7-desktop">
            <div className="card white-bg box-shadow rounded">
              <div className="card-content white-bg box-shadow rounded mt-4">
                <div className="content">
                  {isLoading ? (
                    <div className="has-text-centered">
                      <Spinner />
                    </div>
                  ) : (
                    mainVideoInterface()
                  )}
                  <div className="columns is-centered is-hidden-mobile	">
                    {isLoading ? (
                      <div className="has-text-centered">
                        <Spinner />
                      </div>
                    ) : (
                      charactersMappedComponent()
                    )}
                  </div>
                  <span className="progress-has-value is-hidden-desktop	is-hidden-tablet	is-hidden-widescreen	is-hidden-fullhd">{`${activeExercise ? activeExercise : "0"
                    }/${totalExercise ? totalExercise : "0"}`}</span>
                  <progress
                    className="progress is-success is-medium show-value is-hidden-desktop	is-hidden-tablet	is-hidden-widescreen	is-hidden-fullhd"
                    value={activeExercise}
                    max={totalExercise}
                  ></progress>
                </div>
              </div>
              <footer className="white-bg text-black card-footer is-hidden-desktop is-hidden-widescreen	is-hidden-fullhd">
                {activeExercise === 1 ? (
                  <span className="icon is-small card-footer-item is-centered mt-1 mb-1 has-text-grey-light">
                    <FontAwesomeIcon className="mr-2" icon={faBackward}></FontAwesomeIcon>Prev
                  </span>
                ) : (
                  <span
                    className="icon is-small card-footer-item is-centered mt-1 mb-1"
                    onClick={prevExercise}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faBackward}></FontAwesomeIcon>Prev
                  </span>
                )}
                <span
                  className="icon is-small card-footer-item is-centered mt-1 mb-1"
                  onClick={onRepeat}
                >
                  <FontAwesomeIcon className="mr-2" onClick={onRepeat} icon={faRedo}></FontAwesomeIcon> Repeat
                </span>
                {activeExercise === 50 ? (
                  <span className="icon is-small card-footer-item is-centered mt-1 mb-1 has-text-grey-light">
                    Skip<FontAwesomeIcon className="ml-2" icon={faForward}></FontAwesomeIcon>
                  </span>
                ) : (
                  <span
                    className="icon is-small card-footer-item is-centered mt-1 mb-1"
                    onClick={OnSkip}
                  >
                    Skip<FontAwesomeIcon className="ml-2" icon={faForward}></FontAwesomeIcon>
                  </span>
                )}
              </footer>
            </div>
            <br></br>
            <div className="card  custom-invisiable-style-wider is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only">
              <div className="buttons is-centered py-1 white-bg">
                {charactersMappedComponent()}
              </div>
            </div>
            <div className="is-hidden-desktop	is-hidden-widescreen	is-hidden-fullhd	">
              {whoIsCorrect()}
            </div>
          </div>
          <div className="column is-12-tablet is-5-desktop is-hidden-mobile">
            <div className="card-content">
              <div className="content clickable-div ml-4">
                {/* {charactersMappedComponent()} */}
                <div className="columns mt-4">{controlPanel()}</div>
                <div className="columns ">
                  {
                    //isLoading ?   <div className="has-text-centered"><Spinner /></div> : charactersMappedComponent()
                  }
                  {whoIsCorrect()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <FeedbackModal
          isActive={isFeedbackPopUpActive}
          handleCancel={() => {
            setFeedbackPopUpActive(false)
            setPlaying(true);
          }}
          onFeedbackInput={onFeedbackInput}
          handleFeedbackSubmit={handleFeedbackSubmit}
          feedbackValue={feedback}
        />

        <IncorrectModal_01
          isActive={isPopUpActive}
          onEndedIncorrectModal_01={onEndedIncorrectModal_01}
          handleTryAgain={() => {
            setPopUpActive(false);
            setPlaying(true);
          }}
          handleSkip={OnSkip}
        />

        
        <CorrectModal_01
          isActive={isPopUpWrongActive}
          onEndedCorrectModal_01={onEndedCorrectModal_01}
          handleContinue={nextExercise}
          handlePause={onSaveAndExit}
        />
        
        <PopUpFinal isActive={isFinalActive} onFinalEnded={onFinalEnded} />
        <PopUpTheEnd
          isActive={isTheEnd}
          showResults={showResultsRedirect}
        // handleContinue={nextExercise}
        // handlePause={onSaveAndExit}
        />
      </div>
      <PopulateSurvey activeExercise={activeExercise} />
      <footer className="footer-dashboard is-fixed-bottom">
        <div
          className="level-item is-size-7 has-text-darkgrey pulsating is-clickable"
          onClick={() => {
            setPlaying(false);
            setFeedbackPopUpActive(true);
          }
          }
        >GIVE A FEEDBACK</div>
      </footer>
      <DashboardFooter></DashboardFooter>
    </div>
  );
};
export default GameInterface;
