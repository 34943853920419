import img from "./img/2waylogo.gif";
import HeaderFooterWraper from "./helpers/HeaderFooterWrapper";

const Terms = () => {
  return (
    <>
      <HeaderFooterWraper>
        <section className="hero has-2wayview-bg columns is-vcentered">
          <div className="hero-body column is-12 is-vcentered">
            <p className="privacy-term-title has-text-weight-bold has-text-centered is-size-3">
              TERMS OF USE
            </p>
          </div>
        </section>
        <div className="container ">
          <div className="content p-5 has-text-left">
            <div className="block has-text-white">
              You are using Services (as defined below) developed by IJMCM LLC .
              (hereinafter "IJMCM LLC ."). Please read these Terms of Use
              (hereinafter the{" "}
              <strong className="block has-text-white">"Terms of Use"</strong>)
              carefully before using any Services by IJMCM LLC . By downloading,
              viewing or using any of our Services you are agreeing to these
              Terms of Use. If you do not agree to these Terms of Use, you may
              not use the Services and if you've already begun using Services
              you are required to cease doing so immediately. In order to
              participate in certain Services, you may be required to download
              content, software, and/or required to agree to additional terms
              and conditions.
            </div>
            <div className="block has-text-white">
              The terms “you, your, you’ve” as used herein refer not only to
              individuals, but also to institutions and entities, such as
              hospitals, care facilities, care providers, senior living
              facilities and the like, whether or not incorporated as companies,
              corporations or other limited liability entities, and to the
              managers, directors, employees, consultants and contractors of
              such institutions and entities, jointly and severally.
            </div>
            <div className="block has-text-white">
              Please note that you are using the Services at your own
              responsibility and risk. IJMCM LLC does not take any
              responsibility, or provide any warranties, for the outcomes
              resulting from the use of the Services, nor does it guarantee any
              result of any kind. IJMCM LLC shall not be held responsible for
              any malfunction or damages caused by using Services, downloadable
              files and/or installation of Services.
            </div>
            <div className="block has-text-white">
              Some of the Services are not compatible with the entire variety of
              mobile devices and computers. You shall be solely responsible for
              procuring a compatible device if you wish to use some or all of
              the Services offered by IJMCM LLC .
            </div>
            <div className="block has-text-white">
              THE SERVICES AND ALL MATERIALS CONTAINED IN THEM ARE DISTRIBUTED
              AND TRANSMITTED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
              WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
              PARTICULAR PURPOSE.
            </div>
            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                1. GENERAL TERMS
              </strong>
            </div>
            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                1.1. Governing Agreement
              </strong>
            </div>
            <div className="block has-text-white">
              These Terms of Use govern the relationship between you and IJMCM
              LLCregarding your use of the Services.
            </div>
            <div className="block has-text-white">
              <strong className="block has-text-white">
                IF YOU PROVIDE CONSENT FOR A MINOR TO USE THE SERVICES, YOU
                AGREE TO BE BOUND BY THESE TERMS OF USE IN RESPECT OF THEIR USE
              </strong>
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                1.2. Terms of Use and Privacy Policy
              </strong>
            </div>
            <div className="block has-text-white">
              By using the Services you agree: <br />
              (1) to be bound by these Terms of Use and its conditions; <br />
              (2) to our Privacy Policy which is incorporated here by reference;
              and <br />
              (3) to be bound by other policy and legal notices that may be
              posted on our Site from time to time. <br />
              If you do not agree either to our Terms of Use or our Privacy
              Policy please do not use our Services. You are only authorized to
              use the Services if you agree to abide by and do abide by all
              applicable laws, these Terms of Use and Privacy Policy. We require
              that you review our Privacy Policy and encourage you to raise any
              relevant questions with us.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                1.3. Eligibility for Use Ability to Accept Terms of Use
              </strong>
            </div>
            <div className="block has-text-white">
              You affirm that you have read and accepted these Terms of Use and
              the Privacy Policy, and that you are fully able and competent to
              enter into and comply with the Terms of Use, conditions,
              obligations, affirmations, representations, and warranties set
              forth herein.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                1.4. Updates to the Terms of Use and Conflicting Documents
              </strong>
            </div>

            <div className="block has-text-white">
              IJMCM LLC reserves the right, to change and/or modify these Terms
              of Use from time to time, at its sole discretion. Any such change
              or modification shall come into effect immediately after posting
              them to the Site or Services. By continuing to use our Services
              you accept such changes and modifications in the Terms of Use.
              These Terms of Use shall be effective as of January 1, 2022.
            </div>

            <div className="block has-text-white">
              IJMCM LLC reserves the right, to change and/or modify this free
              offering, at its sole discretion. Any such change or modification
              shall come into effect immediately after posting them to the Site
              or Services and/or proper written notice to our users, and may
              include disconnection after notice
            </div>

            <div className="block has-text-white">
              If as a result of changes or modifications made in the Terms of
              Use or the Privacy Policy you are no longer agreeing to either one
              of them, you must immediately stop using our Services.
            </div>

            <div className="block has-text-white">
              To the extent that these Terms of Use conflict with the Privacy
              Policy or any other IJMCM LLC Terms of Use or policies, these
              Terms of Use shall govern.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                1.5. Ownership
              </strong>
            </div>

            <div className="block has-text-white">
              The Services (including without limitation any content, games,
              melodies, titles, computer code, themes, objects, characters,
              character names, stories, dialogue, catch phrases, concepts,
              artwork, animations, sounds, musical compositions, audio-visual
              effects, methods of operation, and moral rights) are copyrighted
              works owned by IJMCM LLC and its third-party licensors or
              providers, unless expressly indicated otherwise. IJMCM LLC
              reserves all rights, including without limitation, all
              intellectual property rights or other proprietary rights, in
              connection with the Services.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white">
                YOU ACKNOWLEDGE AND AGREE THAT NOTHING IN THESE TERMS OF USE
                SHALL HAVE THE EFFECT OF TRANSFERRING THE OWNERSHIP OF ANY
                COPYRIGHTS, TRADEMARKS, SLOGANS, SERVICE MARKS, TRADE NAMES,
                TRADE DRESS OR OTHER PROPRIETARY RIGHTS IN THE SITE OR CONTENT
                OR ANY PART THEREOF TO YOU OR ANY THIRD PARTY.
              </strong>
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                2. The Services
              </strong>
            </div>
            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                2.1. Definition of Services
              </strong>
            </div>
            <div className="block has-text-white">
              IJMCM LLC provides. Services include:educational games related to
              personal care (hereinafter: the{" "}
              <strong className="block has-text-white">"Services"</strong>).
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                2.2. Authorization to Use the Services
              </strong>
            </div>
            <div className="block has-text-white">
              Subject to your agreement and continuing compliance with these
              Terms of Use and Privacy Policy, you may use IJMCM LLC Services
              for your own purposes.
            </div>
            <div className="block has-text-white">
              No right or license is granted to distribute, publicly perform or
              prepare derivative works of any content accessed through the
              Services.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                2.3. Use of the Services
              </strong>
            </div>
            <div className="block has-text-white">
              You are using the Services at your own responsibility and risk.
              IJMCM LLC does not take any responsibility, or provide any
              warranties, for the outcomes resulting from the use of the
              Services, nor does it guarantee any result of any kind. IJMCM
              LLCshall not be held responsible for any malfunction or damages
              caused by using Services, downloadable files and/or installation
              of Services.
            </div>
            <div className="block has-text-white">
              Some of the Services are not compatible with the entire variety of
              mobile devices and computers. You shall be solely responsible for
              procuring a compatible device if you wish to use some or all of
              the Services offered by IJMCM LLC .
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                2.4. Payment for Services
              </strong>
            </div>
            <div className="block has-text-white">
              The Services require payment. In order to place payments you will
              be redirected to the Apple iTunes Store, Google Play Store, PayPal
              or a similar trusted payment solution (hereinafter:{" "}
              <strong className="block has-text-white">
                "Payment Solution"
              </strong>
              ). All payments shall be placed using Payment Solution, thus
              payment information is not collected and/or saved by IJMCM LLC .
              You are solely responsible for securing your payment information.
              IJMCM LLC shall not be held responsible for any theft,
              unauthorized use and damages of any kind occurring in relation
              with placing payments to use Services.
            </div>
            <div className="block has-text-white">
              IJMCM LLC may at its sole discretion, from time to time, cancel or
              add Services offered for free, increase or decrease the amounts
              required for paid Services, request payment for Services that had
              previously been offered for free and introduce new Services either
              free of charge or paid ones.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                2.5. THIRD PARTY CONTENT
              </strong>
            </div>
            <div className="block has-text-white">
              Services might offer hyperlinks to third party sites and may
              display content and other information from third party sites
              (Hereinafter:{" "}
              <strong className="block has-text-white">
                "Third Party Content"
              </strong>
              ). IJMCM LLC does not verify, endorse, or take responsibility for
              the accuracy, currency, completeness or quality of Third Party
              Content. IJMCM LLC shall not be responsible for any damages,
              errors, omissions or for the results obtained from the use of such
              Third Party Content.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                3. User Account
              </strong>
            </div>
            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                3.1. Account Information
              </strong>
            </div>
            <div className="block has-text-white">
              When creating or updating an account, you may be asked to provide
              IJMCM LLC with certain personal information to improve your
              overall experience and personalize your progress. This information
              will be held and used with utmost care in accordance with the
              Privacy Policy. You agree that you will supply accurate and
              complete information to IJMCM LLC and that you will update that
              information promptly after it changes.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                3.2. Username and Password
              </strong>
            </div>
            <div className="block has-text-white">
              During the account creation process, you may be asked to select a
              password. The following rules govern the security of your
              password:
            </div>

            <div className="block has-text-white">
              -- You shall not share the account or the password, let anyone
              else access your account, or do anything else that might
              jeopardize the security of your account;
              <br />
              -- In the event you become aware of or reasonably suspect any
              breach of security, including without limitation any loss, theft,
              or unauthorized disclosure of the Login Information, you must
              immediately notify IJMCM LLC at{" "}
              <text className="block has-text-black">admin@ijmcm.com</text> and
              modify your password;
              <br />
              -- You are solely responsible for maintaining the confidentiality
              of the password, and you will be responsible for all uses of the
              password;
              <br />
              -- You are responsible for anything that happens through your
              account.
            </div>
            <div className="block has-text-white">
              IJMCM LLC reserves the right to remove or reclaim any usernames at
              any time and for any reason, including but not limited to claims
              by a third party that a username violates such third party's
              rights.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                3.3. Account Limitations
              </strong>
            </div>
            <div className="block has-text-white">
              Any use of the Services in violation of these Limitations is
              strictly prohibited, can result in the immediate revocation of
              authorization to use the Services, and may subject you to
              liability for violations of law.{" "}
              <strong className="block has-text-white">
                You agree that you will not, under any circumstances:
              </strong>
            </div>
            <div className="block has-text-white">
              -- Use the Services, intentionally or unintentionally, in
              connection with any violation of any applicable law or regulation,
              or do anything that promotes the violation of any applicable law
              or regulation;
              <br />
              -- Post any information that is abusive, threatening, obscene,
              defamatory, libelous, or racially, sexually, religiously
              offensive;
              <br />
              -- Make available through the Services any material or information
              that infringes any copyright, trademark, patent, trade secret,
              right of privacy, right of publicity, or other right of any person
              or entity or impersonates any other person;
              <br />
              -- Without IJMCM LLC express written consent, use the Services or
              any part thereof for any commercial purpose;
              <br />
              -- Interfere or attempt to interfere with the proper functioning
              of the Services or connect to or use the Services in any way not
              expressly permitted by these Terms of Use;
              <br />
              -- Reverse engineer, decompile, disassemble, decipher or otherwise
              attempt to derive the source code for any underlying software or
              other intellectual property used to provide the Services, or to
              obtain any information from the Services using any method not
              expressly permitted by IJMCM LLC ; or
              <br />
              -- Copy, modify or distribute rights or content from any Services
              or IJMCM LLC copyrights or trademarks or use any method to copy or
              distribute the content of the Services except as specifically
              allowed in these Terms of Use.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                3.4. Suspension and Termination of Account and Services
              </strong>
            </div>
            <div className="block has-text-white">
              Without limiting any other remedies, IJMCM LLC may limit, suspend,
              terminate, modify, or delete accounts or access to the Services or
              portions thereof if you are, or if IJMCM LLC suspects that you
              are, failing to comply with any of these Terms of Use or for any
              actual or suspected illegal or improper use of the Services, with
              or without notice to you.
            </div>
            <div className="block has-text-white">
              Termination of your account can include disabling your access to
              the Services or any part thereof including any content you
              submitted or others submitted.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                3.5. Account Ownership
              </strong>
            </div>
            <div className="block has-text-white">
              Notwithstanding anything to the contrary herein, you acknowledge
              and agree that you shall have no ownership or other property
              interest in an account, and you further acknowledge and agree that
              all rights in and to an account are and shall forever be owned by
              and inure to the benefit of IJMCM LLC .
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                4. USER Information
              </strong>
            </div>
            <div className="block has-text-white">
              Your information and the contents of all of your online
              communications (including without limitation, IP addresses and
              your personal information) may be accessed as necessary to provide
              the Service, disclosed to data processors who act on our part.
              Whenappropriate, this information may be disclosed, for example,
              without limitation: (i) when we have a good faith belief that we
              are required to disclose the information in response to legal
              process (for example, a court order, search warrant or subpoena);
              (ii) to satisfy any applicable laws or regulations; (iii) where we
              believe that our Services are being used in the commission of a
              crime, including to report such criminal activity; (iv) when we
              have a good faith belief that there is an emergency that poses a
              threat to the health and/or safety of you, another person or the
              public generally; and (v) in order to protect the rights or
              property of IJMCM LLC , including to enforce these Terms of Use;
              (vi) upon a merger, acquisition or any other change of control
              event. By entering into these Terms of Use you hereby provide your
              irrevocable consent to such monitoring, access and disclosure.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                5. UPDATES TO THE SERVICE
              </strong>
            </div>
            <div className="block has-text-white">
              IJMCM LLC may require that you accept updates to the Services and
              to IJMCM LLC content you have installed on your device. You
              acknowledge and agree that IJMCM LLC may update the Service with
              or without notifying you.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                6. DISCLAIMERS AND LIMITATIONS OF LIABILITY
              </strong>
            </div>
            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                6.1. DISCLAIMER OF WARRANTIES
              </strong>
            </div>
            <div className="block has-text-white">
              THE SERVICES AND ALL MATERIALS CONTAINED IN THEM ARE DISTRIBUTED
              AND TRANSMITTED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
              WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
              PARTICULAR PURPOSE.
            </div>
            <div className="block has-text-white">
              IJMCM LLC is not responsible or liable for any infections or
              contamination of your system, or delays, inaccuracies, errors, or
              omissions arising out of your use of the Services or with respect
              to the information and material contained in these Services. The
              entire risk as to the quality, accuracy, adequacy, completeness,
              correctness and validity of any material rests with you.
            </div>
            <div className="block has-text-white">
              TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
              IJMCM LLC , ITS AFFILIATES, AND THEIR RESPECTIVE OFFICERS,
              DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, REPRESENTATIVES, AND
              THIRD PARTY PROVIDERS WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND
              INCLUDING, WITHOUT LIMITATION, COMPENSATORY, CONSEQUENTIAL,
              INCIDENTAL, INDIRECT, SPECIAL OR SIMILAR DAMAGES, THAT MAY RESULT
              FROM THE USE OF, OR THE INABILITY TO USE, THE MATERIALS CONTAINED
              IN THE SERVICES WHETHER THE MATERIAL IS PROVIDED OR OTHERWISE
              SUPPLIED BY IJMCM LLC OR ANY THIRD PARTY.
            </div>

            <div className="block has-text-white">
              Notwithstanding the foregoing, in no event shall IJMCM LLC have
              liability to you for any and all claims, damages, losses, and
              causes of action (whether in contract, tort or otherwise) exceed
              the amount paid by you, if any.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                6.2. Indemnification
              </strong>
            </div>
            <div className="block has-text-white">
              You agree to indemnify, defend and hold harmless, IJMCM LLC , its
              affiliates, and their respective officers, directors, employees,
              agents, licensors, representatives, and third party providers to
              the Sites from and against all losses, expenses, damages and
              costs, including reasonable attorneys' fees, resulting from any
              violation of these Terms of Use by you. IJMCM LLC reserves the
              right to assume, at its sole expense, the exclusive defense and
              control of any matter subject to indemnification by you, in which
              event you will fully cooperate with IJMCM LLC in asserting any
              available defenses. You agree that the provisions in this
              paragraph will survive any termination of your account(s) or of
              the Service.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                7. DISPUTE RESOLUTION
              </strong>
            </div>
            <div className="block has-text-white">
              These Terms of Use and the relationship between you and IJMCM LLC
              shall be governed by the laws of the State of Delware without
              regard to its conflict of law provisions. You agree to submit
              claims and disputes to the exclusive jurisdiction of the competent
              court in Delaware. You hereby waive any and all jurisdictional and
              venue defenses otherwise available.
            </div>
            <div className="block has-text-white">
              You agree that regardless of any statute or law to the contrary,
              any claim or cause of action arising out of or related to the use
              of the Services or the Terms of Use must be filed within one (1)
              year after such claim or cause of action arose or be forever
              barred.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                8. SEVERABILITY
              </strong>
            </div>
            <div className="block has-text-white">
              In the event that any portion of these Terms of Use is found
              illegal or unenforceable, in whole or in part by any court of
              competent jurisdiction, such provision shall, as to such
              jurisdiction, be ineffective solely to the extent of such
              determination of invalidity or unenforceability without affecting
              the validity or enforceability thereof in any other manner or
              jurisdiction and without affecting the remaining provisions, which
              shall continue to be in full force and effect.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                9. PAYMENT POLICY
              </strong>
            </div>
            <div className="block has-text-white">
              The Services require payment of subscription and/or other fees{" "}
              <strong className="has-text-white">(“Paid Content”)</strong>. Fees
              charged are automatically renewing payments as further described
              in the respective Services platform(s). By choosing to access, buy
              or subscribe to any Paid Content, you hereby authorize IJMCM LLC
              to charge the credit card or any other payment method that you
              choose to use, for all the costs and charges that apply to such
              Paid Content. You acknowledge and agree that you are solely
              responsible for all applicable fees and charges incurred,
              including applicable taxes, and all subscription fees and
              purchases made by you or by anyone that has used your account(s).
              If you are acting on behalf of an entity, you represent that you
              are authorized to act on behalf of such entity. Please note that
              IJMCM LLC may use a third-party payment processor to facilitate
              payments for the Services. If you make a purchase through a
              third-party application store, such as Apple App Store or Google
              Play Store, your purchase will be subject to such third party’s
              applicable payment policy, terms of use and other applicable
              policy's such third parties may have in place.
            </div>
            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                9.1. Payment Information
              </strong>
            </div>
            <div className="block has-text-white">
              To access Paid Content, you must provide valid and accepted
              payment information. If the payment information provided by you is
              declined for payment of the Paid Content you are subscribed to,
              you must provide IJMCM LLC with alternative valid payment
              information or your access to Paid Content may be suspended at
              IJMCM LLC sole discretion. In such an event, you will get access
              to Paid Content as soon as the provided payment information has
              been processed and approved.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">9.2. Trials</strong>
            </div>
            <div className="block has-text-white">
              When you subscribe for Paid Content, IJMCM LLC may, from time to
              time at its sole discretion offer a trial period in which such
              Paid Content may be accessed without payment or at a reduced rate{" "}
              <strong className="has-text-white">(“Trial Period”)</strong>. This
              may vary depending on promotions, type of subscription and the
              term you select at the time of purchase. IJMCM LLC reserves the
              right, in its absolute discretion, to determine your eligibility
              for a Trial Period and may cancel or change the Trial Period terms
              at any time without notice and/or liability to you, within the
              limits permitted by applicable law. IJMCM LLC may require you to
              provide your valid payment information in order to start the Trial
              Period. By providing this information, you agree that unless you
              choose to cancel your paid subscription prior to the end of the
              Trial Period, IJMCM LLC may automatically begin charging you for
              such Paid Content, starting on the first day following the Trial
              Period, on a monthly (or other recurring) basis as notified to
              you, until you cancel your subscription to such Paid Content (i.e.
              turn off automatic renewal from your 'account settings' or via the
              Apple App Store or the Google Play Store). If you do not want to
              be charged, you must cancel your subscription to such Paid Content
              before the end of the Trial Period. If you cancel your
              subscription to Paid Content, your Account will only have access
              to those parts of our Services that you may access for free.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                9.3. Billing, Renewals And Cancellations
              </strong>
            </div>
            <div className="block has-text-white">
              The subscription fee for our auto-renewable subscription plans may
              be billed as one-time advance payment for the subscribed period
              (e.g., 1, 3, 6 or 12 months) or in monthly installments charged
              every month in advance for the duration of the subscription
              period. The pricing and terms applicable to your subscription may
              vary depending on promotions, type of subscription and the
              subscription period you select at the time of purchase. If your
              subscription started with a promotion or Trial Period at a reduced
              rate, your renewal rate may be higher than your initial rate. In
              some cases, your payment billing dates or billing term for your
              subscription may change if your payment was not successfully
              finalized on the original due date. IJMCM LLC will use reasonable
              efforts to inform you of the billing term change through email. If
              you have registered for any Paid Content, you may change your mind
              and receive a full refund of all monies paid within thirty (30)
              days from your subscription to such Paid Content, provided that
              during such period you have not at any time used or otherwise
              accessed such Paid Content. If you choose to sign up for an
              auto-renewable subscription for Paid Content, you understand and
              agree that your subscription will automatically renew at the end
              of the subscription period, and that payment for the renewal
              period is automatically charged to you using the payment
              information you have provided, unless you cancel your subscription
              before the end of the then-current subscription period. You can
              cancel your auto-renewing subscription to Paid Content via your
              account settings or by contacting our support by opening a support
              ticket on the Site or applicable app. If you purchased a paid
              subscription to Paid Content via a third party, such as Apple App
              Store or Google Play Store, you can cancel such paid subscription
              via such third party following the applicable terms and payment
              policies of such third party. Except as specifically provided
              otherwise in these Terms of Use, you hereby agree that any payment
              made by you to IJMCM LLC is and will be FINAL and NON-REFUNDABLE.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                9.4. Price Changes
              </strong>
            </div>
            <div className="block has-text-white">
              IJMCM LLC prices and fees may change from time to time. If the
              pricing of your Paid Content changes, IJMCM LLC will notify you in
              advance and provide you with an opportunity to review such changes
              to your subscription fee. Price changes will take effect at the
              beginning of the next subscription period after the date of the
              price change. If you do not agree to a price change, then your
              only right is to terminate your account. To the extent permitted
              under applicable law, continuing to use the Services after any
              price or fee change takes effect, shall indicate that you accept
              such price or fee change. If you do not accept a price change,
              your only right is to refuse the change by canceling the
              subscription to Paid Content before the price change takes effect.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-4">
                10. GENERAL PROVISIONS
              </strong>
            </div>
            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                10.1. Assignment
              </strong>
            </div>
            <div className="block has-text-white">
              You may not assign or delegate any rights or obligations under the
              Terms of Use or Privacy Policy without IJMCM LLC prior written
              consent, and any unauthorized assignment and delegation by you is
              void and ineffective.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                10.2. Entire Agreement
              </strong>
            </div>
            <div className="block has-text-white">
              These Terms of Use contain the entire understanding of you and
              IJMCM LLC and supersede all prior understandings, whether
              electronic, oral or written, or whether established by custom,
              practice, policy or precedent, between you and us with respect to
              the Services.
            </div>

            <div className="block has-text-white">
              <strong className="has-text-white is-size-5">
                10.3. Notices
              </strong>
            </div>
            <div className="block has-text-white">
              We may notify you via e-mail or by posting on the Site.
            </div>
            <div className="block has-text-white">
              All notices given by you or required from you under these Terms of
              Use shall be in writing and addressed{" "}
              <text className="block has-text-black"> admin@ijmcm.com</text>.
              Any notices that you provide without compliance with this Section
              on Notices shall have no legal effect.
            </div>
          </div>
        </div>
      </HeaderFooterWraper>
    </>
  );
};
export default Terms;
