import React from 'react';

const StartGameModal = ({ isActive, handleCancel, handleStart }) => {
    return (
        <div className={`modal modal-fx-fadeInScale is-clipped ${isActive ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-content white-bg rounded">
                <div className="card white-bg">
                    <div className="card-content has-text-centered rounded ">
                        <h1 className="is-size-5 " style={{color: 'red'}}> Starting a new game will delete all your previous results!</h1>
                    </div>
                    <footer className="card-footer border-none">
                        <a className=" card-footer-item is-danger transparent-bg border-none  has-text-dark  " onClick={handleStart}>OK</a>
                        <a className=" card-footer-item is-warning border-none warning-bg has-text-dark" onClick={handleCancel}>Cancel</a>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default StartGameModal;