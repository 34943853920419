import ReactPlayer from 'react-player';
import config from '../../utils/config';

const videoUrl = config.sorry_that_is_not_the_right_answer_please_try_again;
const IncorrectModal_01 = ({isActive, onEndedIncorrectModal_01, handleTryAgain, handleSkip}) => {

  return (
    <div   className={`modal modal-fx-fadeInScale is-clipped ${isActive ? 'is-active' : ''}`}>
      <div    className="modal-background"></div>
      <div    className="modal-content">
        <div className="card">
          <div className="card-content">
            <ReactPlayer 
              width="100%"
              height="100%"
              playing={isActive}
              url={videoUrl}
              onEnded={onEndedIncorrectModal_01}
            />
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default IncorrectModal_01;