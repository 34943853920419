/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Link
} from "react-router-dom";
import brandImage from '../img/Fall-prevention-logo-white.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAppStoreIos, faGooglePay } from '@fortawesome/free-brands-svg-icons';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [isActiveNav, setActiveNav] = useState(false);

  const toggleMenuBurger = () => {
    setActiveNav(!isActiveNav);
  }
  return (
    <nav className="navbar transparent-bg has-text-white" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item navigation-links" href="/">
            <div className="image">
              <img src={brandImage}  />
            </div>
          </a>
          <a role="button" onClick={() => toggleMenuBurger() } className={`navbar-burger`} >
        {
          isActiveNav ? <FontAwesomeIcon icon={faX} /> : <FontAwesomeIcon icon={faBars} />
        }
        </a>
        </div>
        <div id="navbarBasicExample" className={`navbar-menu ${isActiveNav ? 'is-active' : ''} `}>
          
          <div className="navbar-end">
            <div className="navbar-item">
              <Link className="ml-3 navbar-item navigation-links" to="/PirvacyPolicy">
                Privacy Policy
              </Link>
              <Link className="ml-3 navbar-item navigation-links" to="/Terms">
                Terms
              </Link>
              <Link className="ml-3 navbar-item navigation-links" to="/ContactUs">      
                Contact Us
              </Link>
              
            </div>
          </div>
        </div>
      </div>
    </nav>
  )

}

export default Header;

