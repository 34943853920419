import axios from 'axios';
import { useNavigate, Navigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import config from '../../utils/config';
import DashboardFooter from '../helpers/DashboardFooter';
import DashboardHeader from '../helpers/DashboardHeader';
import moment from 'moment';
import { useState, useEffect } from 'react';
import Modal from '../helpers/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const cancelUrl = config.individual_cancel_subscription;
const updateUrl = config.individual_update_subscription;
const CalculatePriceWithDiscount = config.calculate_price_with_discount;
const IndividualSubscriptionPrice = config.indivudal_Subscription_price;
const individualSubscriptionDetails = config.getSubscrptionDetails;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const PaymentForm = ({ onSubmit, isLoading, price, discountCode, setDiscountCode, discountData, isDisccountError, discountLoading }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      alert(error.message);
      return;
    }

    onSubmit({
      paymentMethodId: paymentMethod.id,
      discountCode: discountCode
    });
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#4a4a4a',
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        lineHeight: '24px',
        '::placeholder': {
          color: '#545454',
        },
        backgroundColor: 'white',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <p
              className={`control has-icons-left ${
                discountLoading ? "is-loading" : ""
              }`}
            >
              <input
                className="input"
                type="text"
                placeholder="Discount Code"
                onChange={(e) => setDiscountCode(e.target.value)}
                value={discountCode}
                disabled={discountLoading}
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faPercentage} />
              </span>
              {isDisccountError && (
                <p className="help is-danger">{isDisccountError}</p>
              )}
            </p>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                type="text"
                value={`Total Amount : $ ${
                  discountData ? discountData.totalPrice : price + ".00"
                }`}
                readOnly
                disabled
              />
              {discountData && (
                <p className="help is-success has-text-weight-bold">
                  You got {discountData.discountPercentage}% discount
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="field">
        <div className='text-black label'>Card Details</div>
        <div className="control">
          <CardElement
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <button
            className={`button is-primary is-fullwidth ${
              isLoading ? "is-loading" : ""
            }`}
            onClick={handleSubmit}
            disabled={!stripe}
          >
            Subscribe
          </button>
        </div>
      </div>
    </>
  );
};

const DashBoardWrapper = () => {
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const [subscriptionMessage, setSubscriptionMessage] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(false);
  const [discountCode, setDiscountCode] = useState(null);
  const [discountData, setDiscountData] = useState();
  const [isDiscountError, setDiscountError] = useState();
  const [discountLoading, setDiscountLoading] = useState(false);
  const [price, setPrice] = useState(null);

  useEffect(() => {
    fetchPrice();
    getSubscriptionDetails();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => fetchDiscountCalculation(discountCode), 1000);
    return () => clearTimeout(timeOutId);
  }, [discountCode]);

  const fetchDiscountCalculation = (discountCode) => {
    if (!discountCode) {
      setDiscountData(null);
      setDiscountError(null);
      return;
    }

    setDiscountLoading(true);
    axios.post(CalculatePriceWithDiscount, { discountCode })
      .then(response => {
        setDiscountError(null);
        setDiscountData(response.data);
        setDiscountLoading(false);
      })
      .catch(error => {
        setDiscountError(error.response?.data?.message || 'Error processing discount');
        setDiscountData(null);
        setDiscountLoading(false);
      });
  };

  const fetchPrice = () => {
    axios.get(IndividualSubscriptionPrice)
      .then(response => {
        setPrice(response.data.price);
      })
      .catch(error => {
        console.error('Error fetching price:', error);
      });
  };

  const cancelSubscription = () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      axios.patch(cancelUrl, {}, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(response => {
          alert(response.data.message);
          localStorage.clear();
          navigate('/');
        })
        .catch(error => {
          alert(error.response?.data?.message || 'Error canceling subscription');
        });
    }
  };

  const getSubscriptionDetails = () => {
    axios.get(individualSubscriptionDetails, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => {
        setSubscriptionDetails(response.data);
      })
      .catch(error => {
        alert(error.response?.data?.message || 'Error fetching subscription details');
      });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    
    try {
      const response = await axios.patch(updateUrl, {
        paymentMethodId: data.paymentMethodId,
        discountCode: data.discountCode
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      setLoading(false);
      localStorage.setItem('user', JSON.stringify(response.data.user || {}));
      setSubscriptionMessage(response.data);
      setModalOpen(true);
    } catch (error) {
      setLoading(false);
      alert(error.response?.data?.message || 'Error processing payment');
    }
  };

  if (!user) return <Navigate to="/" />;
  if (user.userType !== "individualUser") return <Navigate to="/dashboard" />;

  return (
    <div className="main" style={{ overflowY: 'hidden', overflowX: 'hidden', position: 'static', height: 'auto' }}>
      <DashboardHeader />
      <main className="main-content-dashboard px-2">
        <div className="columns has-padding-mobile">
          <div className="column is-three-fifths is-offset-one-fifth mt-2">
            <div className="container">
              {!user.isSubscribed ? (
                <div className="card white-bg rounded">
                  <header className="card-header">
                    <p className="card-header-title text-black">Subscribe Again</p>
                  </header>
                  <div className="card-content">
                    <Elements stripe={stripePromise}>
                      <PaymentForm
                        onSubmit={onSubmit}
                        isLoading={isLoading}
                        price={price}
                        discountCode={discountCode}
                        setDiscountCode={setDiscountCode}
                        discountData={discountData}
                        isDisccountError={isDiscountError}
                        discountLoading={discountLoading}
                      />
                    </Elements>
                  </div>
                </div>
              ) : (
                <div className="card white-bg rounded card-has-shadow">
                  <header className="card-header white-bg rounded">
                    <p className="card-header-title text-black">Subscription Details</p>
                  </header>
                  <div className="card-content white-bg rounded">
                    {isModalOpen && (
                      <Modal
                        isActive={isModalOpen}
                        title={'Payment Info'}
                        OnModalToggle={() => setModalOpen(!isModalOpen)}
                        data={subscriptionMessage}
                      />
                    )}
                    {subscriptionDetails && (
                      <div className="content">
                        <p className="subtitle text-black is-5">
                          Subscription Price: ${subscriptionDetails.subscriptionPrice.toFixed(2)}
                          <span className='has-text-success is-size-7'> (Discount will be applied if applicable.)</span>
                        </p>
                        {subscriptionDetails.subscriptionPrice && (
                          <p className="subtitle text-black is-5">
                            Amount Charge for this month: ${subscriptionDetails.lastCharge}
                          </p>
                        )}
                        <p className="subtitle text-black is-5">Plan: Monthly</p>
                        <p className="subtitle text-black is-5">
                          Next Billing Date: {moment(subscriptionDetails.subscriptionEndDate).format('dddd, MMMM Do YYYY')}
                        </p>
                        <button className="button is-danger" onClick={cancelSubscription}>
                          Cancel Subscription
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <DashboardFooter />
    </div>
  );
};

export default DashBoardWrapper;