import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import HeaderFooterWrapper from '../helpers/HeaderFooterWrapper';
import Panel from '../helpers/Panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek, faCheck, faCreditCard, faEnvelope, faLock, faPercentage } from '@fortawesome/free-solid-svg-icons';

const signUpUrl = config.individual_sing_up;

const IndividualUserSignUp = ( ) => {
  const BaseUrl = window.location.href.split('/')[2];
  
  const [ isLoading, setLoading ] = useState(false)
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [isErrorMessage, setErrorMessage] = useState();

  const onSubmit =  data => { 
    setLoading(true);
    var config = {
      method: 'post',
      url: signUpUrl,
      headers: { },
      data : data
    };
    axios(config)
    .then(function (response) {
      setLoading(false)
      window.location.replace('http://'+BaseUrl+'/facilityUserLogin');
    })
    .catch(function (error) {
     var err = error.response.data.message
      setErrorMessage(err)
      setLoading(false)
    });
  };
  return (
    <HeaderFooterWrapper>
      <div className="columns py-3">
        <div className="column is-three-fifths is-offset-one-fifth  mt-2">
          <Panel
            title="Sign Up"
            subTitle="Login"
            subTitleLink="/individualUserLogin"
          >
            {isErrorMessage &&  
              <div className="message is-danger">
                <div className="message-body">{isErrorMessage}</div>
              </div>
             }
             <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <p className="control has-icons-left">
                    <input className={`input ${errors.email ? 'is-danger' : ''}`} type="email" placeholder="Email" {...register('email', { required: 'Email Required' })} />
                        <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                        </span>
                        {errors.email && <p className="help is-danger">{errors.email.message}</p>}
                    </p>
                  </div>
                  <div className="field">
                    <p className="control has-icons-left">
                  <input
                    className={`input ${errors.password && 'is-danger'}`}
                    type="password"
                    placeholder="Password"
                    name="password"
                    ref={register({
                      required: "Password Required",
                      minLength: { value: 8, message: 'Password too short, minimum 8 digits' }
                    })}
                  />

                  {errors.password && <p className="help is-danger">{errors.password.message}</p>}

                        <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                        </span>
                        {errors.password && <p className="help is-danger">{errors.password.message}</p>}
                    </p>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <p className="control has-icons-left">
                          <input className="input" type="text" placeholder="Discount Code" name="discountCode" ref={register}/>
                          <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faPercentage}></FontAwesomeIcon>
                          </span>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control has-icons-left">
                        <input
                          className={`input ${errors.number && 'is-danger'}`}
                          type="text"
                          placeholder="Card Number"
                          name="number"
                          ref={register({ required: 'Credit Card number is required' })}
                        />
                        {errors.number && <p className="help is-danger">{errors.number.message}</p>}
                          <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon>
                          </span>
                          {errors.number && <p className="help is-danger">{errors.number.message}</p>}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className={`input ${errors.expMonth && 'is-danger'}`}
                            type="number"
                            placeholder="Exp. Month"
                            name="expMonth"
                            ref={register({ required: 'Exp. Month required', minLength: 2, maxLength: 2 })}
                          />
                          {errors.expMonth && <p className="help is-danger">{errors.expMonth.message}</p>}
                          <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faCalendarWeek}></FontAwesomeIcon>
                          </span>
                          <span className={`icon is-small is-right ${!errors.expMonth ?'has-text-success' : 'has-text-danger'}`}>
                          <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                          </span>
                          {errors.expMonth && <p className="help is-danger">{errors.expMonth.message}</p>}
                           <p className={`help  ${errors.expMonth ? 'is-danger' : 'is-success' }`}>Maximum 2 digits</p>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                        <input
                          className={`input ${errors.expYear && 'is-danger'}`}
                          type="number"
                          placeholder="Exp. Year"
                          name="expYear"
                          ref={register({ required: 'Exp. Year required', minLength: 2, maxLength: 2 })}
                        />
                        {errors.expYear && <p className="help is-danger">{errors.expYear.message}</p>}
                          <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faCalendarWeek}></FontAwesomeIcon>
                          </span>
                          <span className={`icon is-small is-right ${!errors.expYear ?'has-text-success' : 'has-text-danger'}`}>
                          <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                          </span>
                          {errors.expYear && <p className="help is-danger">{errors.expYear.message}</p>}
                           <p className={`help  ${errors.expYear ? 'is-danger' : 'is-success' }`}>Maximum 2 digits</p>
                        </p>
                      </div>

                      <div className="field">
                        <p className="control is-expanded has-icons-left has-icons-right">
                          <input
                            className={`input ${errors.cvc && 'is-danger'}`}
                            type="number"
                            placeholder="CVC"
                            name="cvc"
                            ref={register({ required: 'CVC is required', minLength: 3, maxLength: 3 })}
                          />
                          {errors.cvc && <p className="help is-danger">{errors.cvc.message}</p>}
                          <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon>
                          </span>
                          <span className={`icon is-small is-right ${!errors.cvc ?'has-text-success' : 'has-text-danger'}`}>
                          <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                          </span>
                          {errors.cvc && <p className="help is-danger">{errors.cvc.message}</p>}
                           <p className={`help  ${errors.cvc ? 'is-danger' : 'is-success' }`}>Maximum 3 digits</p>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <p className="control">
                      <button className={`button is-success ${isLoading ? 'is-loading' : ''} is-pulled-right`} type="submit" >Sign Up</button>
                    </p>
                </div> 
             </form>
          </Panel>
        </div>
      </div>
    </HeaderFooterWrapper>
  )
}

export default IndividualUserSignUp;