import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

var LoginUrl = config.facility_login;

const Login = () => {
    const [ isLoading, setLoading ] = useState(false)
    const [ isErrorMessage, setErrorMessage ] = useState()
    const { register, handleSubmit, watch, formState: { errors } } = useForm({mode : 'onChange'});
    let navigate = useNavigate();
    const onSubmit = async (data) => { 
      setLoading(true);
      var config = {
        method: 'post',
        url: LoginUrl,
        headers: { },
        data : data
      };
      axios(config)
      .then(function (response) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user ? response.data.user : {}));
        setLoading(false)
        navigate("/dashboard");
      })
      .catch(function (error) {
       var err = error.response.data.message
        setErrorMessage(err)
        setLoading(false)
      });
    };

    const renderItems = () => {
      if(isErrorMessage){
        return(
          <div className="message is-danger">
            <div className="message-body">{isErrorMessage}</div>
          </div>
        )
      }
    }
    return (
      <div className="form-holder is-shaking">
      {renderItems()}
        <form  onSubmit={handleSubmit(onSubmit)}>
          <div className="field">
            <p className="control has-icons-left ">
              <input
                className={`input ${errors.email && 'is-danger'}`}
                type="email"
                placeholder="Email"
                name="email"
                ref={register({ required: 'Email Required' })}
              />
              <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
              </span>
              {errors.email && <p className="help is-danger">{errors.email.message}</p>}
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className={`input ${errors.password && 'is-danger'}`}
                type="password"
                placeholder="Password"
                name="password"
                ref={register({ required: 'Password Required' })}
              />
              <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
              </span>
              {errors.password && <p className="help is-danger">{errors.password.message}</p>}
            </p>
          </div>
          <div className="field">
            <p className="control">
              <button className={`button is-success ${isLoading ? 'is-loading' : ''} is-pulled-right`} type="submit" >Login</button>
            </p>
          </div>
        </form>
      </div>
    )
}

export default Login