import axios from 'axios';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import config from '../../utils/config';
import HeaderFooterWraper from '../helpers/HeaderFooterWrapper';
import Loader from '../helpers/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const ResetPasswordUrl = config.commonResetPassword;

const ResetPassword = () => {
  const forgotPasswordToken = window.location.pathname.split('/')[2];
  const resetPassUrlWithToken = ResetPasswordUrl+'/'+forgotPasswordToken
  
  const [ isLoading , setLoading ] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState();
  const [isErrorMessage, setErrorMessage] = useState();
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const resetPassword = (data) => {
    setLoading(true);
    var config = {
      method: 'post',
      url: resetPassUrlWithToken,
      headers: { },
      data : data
    };
    axios(config)
    .then(function (response) {
      setSuccessMessage(response.data.message)
      setLoading(false)
    })
    .catch(function (error) {
      const err = error.response.data.errors
      setErrorMessage(err)
      setLoading(false)
    });
  }

  const closeErrorNotification = () => {
    setErrorMessage();
  }

  const BoxContent = () => {
    if(isSuccessMessage){
      return (
        <>
          {isLoading ?  <Loader size="small"  />  : <div className="notification is-success is-light">{isSuccessMessage}</div> }
        </>
      )
    }
    if(isErrorMessage){
      return(
        <>
          {
            isLoading ? <Loader size="small"  />  :    <div className="notification is-danger  is-light"><button className="delete" onClick={closeErrorNotification}>Try Again</button>{isErrorMessage}</div>
          }
        </>
      )
    }else{
      return(
        <>
         {
           isLoading ? <Loader size="small"  />  : 
          <div className="field">
            <p className="control has-icons-left">
                  <input
                    className={`input ${errors.newPassword && 'is-danger'}`}
                    type="password"
                    placeholder="New Password"
                    name="newPassword"
                    ref={register({
                      required: "Password Required",
                      minLength: { value: 8, message: 'Password must be at least 8 characters long' }
                    })}
                  />
              <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faLock} className="has-text-danger" />              </span>
              {errors.newPassword && <p className="help is-danger">{errors.newPassword.message}</p>}
            </p>
          </div>
         }
        </>
      )
    }
  }

  const BoxButton = () => {
    if(isErrorMessage){
      return (
        <Link className="is-helper is-info" to="/" > Go back to Login </Link>
      )
    }
    if(isSuccessMessage){
      return <Link className="is-helper is-info" to="/" >  Login </Link>
    }else{
      return (
        <button className={`button is-success ${isLoading ? 'is-loading' : ' '} `} type="submit" >Reset Password</button>
      )
    }
  }

  return (
    <HeaderFooterWraper>
      <div className="columns has-text-centered">
        <div className="column is-half is-offset-one-quarter">
          <div className="box">
            <h1 className="title ">Reset Password</h1>
            <form onSubmit={handleSubmit(resetPassword)}>
              {BoxContent()}
              <div className="field">
                <p className="control">
                  {BoxButton()}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </HeaderFooterWraper>
  )
}

export default ResetPassword;