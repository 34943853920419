import { useEffect, useState } from "react";
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

const GameId = '1';
const ReportParts = (props) => {

  const [showBox, setShowBox] = useState(false)
  const [showBoxId, setShowBoxId] = useState()
  const [boxData, setBoxData] = useState({})
  const [exercise, setExercise] = useState([])
  const [attemptPerPart, setAttemptPerPart] = useState()
  const [multipleTryPercentage, setMultipleTryPercentage] = useState()
  const [firstTryPerecntage, setFirstTryPerecntage] = useState()
  const [firstTryPerecnfirstPercentagetage, setfirstTryPerecnfirstPercentagetage] = useState([])
  const [correctPerecntage, setCorrectPercentage] = useState()
  const [correctPlayedForProgress, SetcorrectPlayedForProgress] = useState()
  const [totalExercisePerPart, setTotalExercisePerPart] = useState()
  const [startExerciseDate, setExerciseDate] = useState()
  const [percentage, setPercentage] = useState()
  const [firstTime, setFirstTime] = useState(false)
  const [counAttempt, setAttempt] = useState()
  const [checkSkipped, setSkipped] = useState(false)
  const [checkIncomplete, setIncomplete] = useState(false)

  const navigate = useNavigate();

  const ToggleBox = (item) => {
    checkWhichAttempIsCorrect(item)

    let id = item.exercise_id
    let time = item.time;
    let repeats = item.repeats;
    let skip = item.skip;
    let i = item.main.length - 1
    let StartedOn = item.main[0].attempted_at
    let totalAttempt = 0;
    let main = item.main
    for (let ctx of main) {
      totalAttempt = totalAttempt + 1;
    }
    setBoxData({
      id,
      time,
      repeats,
      skip,
      StartedOn,
      totalAttempt
    })
    if (showBoxId && showBoxId === id) {
      setShowBox(!showBox)
    } else {
      setShowBox(true)
      setShowBoxId(id)
    }

  }

  const checkWhichAttempIsCorrect = (item) => {
    setFirstTime(false)

    let arr = item && item.main
    var i = 0;
    if (item.main[0].attempt_outcome === 'incorrect') {
      setIncomplete(true)
      setAttempt()
    }
    if (item.skip === true) {
      setSkipped(true)
      setAttempt()
    }
    if (item.main[0].attempt_outcome === 'correct') {
      setFirstTime(true)
      setAttempt()
    } else {
      for (i; i < item.main.length; i++) {
        if (item.main[i].attempt_outcome === 'correct') {
          setAttempt(i);
          setFirstTime(false)
        }

      }
    }

  }

  const completeExercise = (id) => {
    return exercise.map((item) => {
      if (item.exercise_id === id) {
        if (item.skip === true) {
          return <div className="tag is-warning">Skipped</div>
        }
        else if (!itemPlayedCorrect(item)) {
          return <div className="tag is-info">Incomplete</div>
        }
        else if (itemPlayedCorrect(item)) {
          return <div className="tag is-success">Completed</div>
        }
      }
    })
  }

  useEffect(() => { getChapteres(props) }, [])

  const getChapteres = (props) => {
    // console.log(props)
    let arr = props.data
    let chp_start = props.chp_start
    let chp_end = props.chp_end

    let results = arr.filter(item => item.exercise_id >= chp_start && item.exercise_id <= chp_end)
    setExercise(results)
    reportCalculationForPart(results)

  }

  const FirstTryOnPerPart = (exercise) => {
    let length = exercise.length

    let countFirstTry = 0;

    for (let exc of exercise) {
      if (exc.main[0].attempt_outcome === 'correct') {
        countFirstTry = countFirstTry + 1
      }
    }
    setPercentage(countFirstTry)
  }

  const reportCalculationForPart = (results) => {
    let exercise = results
    // console.log(exercise);
    
    let TotalRepeats = 0
    let totalIncorrect = 0
    let totalCorrect = 0
    let correctOnFirstTry = 0
    let totalAttemptPerExercise = 0
    let totalAttemptPerpart = 0
    let totalSkipped = 0
    let totalCorrectPlayed = 0
    let totalExercise = exercise.length
    let startDate;
    let mainLegnt
    FirstTryOnPerPart(exercise && exercise)

    for (let item of exercise) {
      startDate = item.main[0].attempted_at
      //setExerciseDate(item.main[0].attempted_at)

      totalSkipped = totalSkipped + item.skip
      TotalRepeats = TotalRepeats + item.repeats
      if (itemPlayedCorrect(item)) {
        totalCorrectPlayed = totalCorrectPlayed + 1
      }

      let main = item.main
      mainLegnt = main.length


      for (let chunk of main) {
        if (chunk.attempt_outcome === "incorrect") {
          totalIncorrect = totalIncorrect + 1;
        }
        if (chunk.attempt_outcome === "correct") {
          totalCorrect = totalCorrect + 1;
        }
      }
      if (item.main[0].attempt_outcome === 'correct') {
        correctOnFirstTry = correctOnFirstTry + 1;
      }
    }
    setTotalExercisePerPart(totalExercise)
    SetcorrectPlayedForProgress(totalCorrectPlayed)

    let correctPercentage = totalCorrect / totalExercise * 100
    let incorrectPercentage = totalIncorrect > 0 ? totalIncorrect / totalExercise * 100 : 0    //totalIncorrect > 0 ? totalExercise / totalIncorrect * 100 : 0
    totalAttemptPerpart = totalCorrect + totalIncorrect;
    let correctOnFirstTryPercentage = correctOnFirstTry > 0 ? (correctOnFirstTry / totalAttemptPerpart) * 100 : 0

    setAttemptPerPart(totalAttemptPerpart);
    setMultipleTryPercentage(incorrectPercentage)
    setFirstTryPerecntage(correctOnFirstTryPercentage)
    setCorrectPercentage(correctPercentage)

  }

  const ButtonMappedContent = () => {
    return exercise.map((item) => {
      if (item.skip === true) {
        return <button className="button rounded is-warning" onClick={() => ToggleBox(item)}>{item.exercise_id}</button>
      }  else if (isItemPlayedOnce(item) && itemPlayedCorrect(item)) {
        return <button className="button rounded is-success" onClick={() => ToggleBox(item)}>{item.exercise_id}</button>
      }  else if (isItemPlayedOnce(item) && !itemPlayedCorrect(item)) {
        return <button className="button rounded is-info" onClick={() => ToggleBox(item)}>{item.exercise_id}</button>
      } else {
        <button className="button rounded is-dark" onClick={() => ToggleBox(item)}>{item.exercise_id}</button>
      }
    })
  }

  const isItemPlayedOnce = (item) => {
    if (item.main.length > 0) {
      return true
    } else {
      return false
    }
  }
  const itemPlayedCorrect = (item) => {
    for (let attmept of item.main) {
      if (attmept.attempt_outcome === 'correct') {
        return true
      }
    }
    return false
  }
  const isItemSkipped = (item) => {
    if (item.skip === true) {
      checkSkipped(true)
      return true
    }
    return false
  }

  const handleExerciseClick = (key) => {
  navigate('/gameInterface', { 
    state: {
      GameId,
      lastExercise: key - 1,
      resume: true,
      newSession: false
    }
  });
  }

  return (
    <>
      <div className="level-dashboard d-flex">
        <div className="">
          <div className="level-item has-text-centered-mobile">
            <p className="subtitle text-black is-7">{props.partName}</p>
          </div>
        </div>
        <div className="has-text-right">
          <div className="level-item">
            {attemptPerPart === 0 ? <p className="subtitle text-black is-7 mx-5">You have not answered this exercise</p> : <p className="subtitle text-black is-7 mx-5">Total Attempts : {attemptPerPart}</p>}
          </div>
          {/* <span>Correct:{multipleTryPercentage}%</span> */}
        </div>
      </div>

      {
        attemptPerPart != 0 ?
          <div className="level">
            <div className="has-text-centered-mobile">
              <p className="subtitle text-black text-black is-7">{Math.round(percentage)} {percentage > 1 || percentage === 0 ? 'exercises' : 'exercise'}  correct on the first attempt  </p>
            </div>
          </div>
          :
          null
      }

      <div className="level-item m-1">
        <div className="buttons are-small mb-3">
          {ButtonMappedContent()}
        </div>
      </div>

      {
        showBox && <div className="box-has-outline mr-3">
          <div className="columns is-mobile">
            <div className="column">
              <p className="title text-black is-6"><span className="has-text-grey text-black" style={{ 'cursor': 'pointer' }} onClick={() => handleExerciseClick(boxData.id)}>Exercise</span> {boxData.id}</p>
              {counAttempt && <p className="subtitle is-7 py-2"><span className="has-text-grey  ">Correct  after {counAttempt + 1} attempts </span> </p>}
              {firstTime && <p className="subtitle is-7 py-2 mt-4"><span className="has-text-grey">Correct on first attempt  </span> </p>}
            </div>
            <div className="column">
              <div className="is-pulled-right">
                <p className="subtitle is-7"><span className="has-text-grey text-black">Last Date</span> : <Moment className="text-black" format="dddd, MMMM Do YYYY, h:mm a">{boxData.StartedOn}</Moment></p>
                {completeExercise(boxData.id)}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default ReportParts