import React, { useState } from 'react';
import android from '../components/img/Android.png';
import ios from '../components/img/IOS.png';
import TitlePSD from '../components/img/Title-PSD.png';
import HeaderFooterWraper from './helpers/HeaderFooterWrapper';
import InstructionsVideoModal from './helpers/InstructionVideoModal';
import SignIn from './helpers/SignIn';
//const instructionalVideoUrl = config.instructional_video;

const HomePage = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isActive, setActive] = useState(false);

  const handleModalActive = () => {
    setActive(!isActive);
    document.getElementById("Xmain").style.removeProperty('height')
  }

  if (isActive) {
    document.getElementById("Xmain").style.height = "100vh";
  }

  return (
    <HeaderFooterWraper>
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column ">
            <figure className="image">
              <img src={TitlePSD} />
            </figure>
            <div className="column mx-6">
                <button className="button is-rounded is-medium is-fullwidth text-blue  is-light" onClick={handleModalActive}>How to play the game</button>
            </div>
          </div>
          <div className="column mx-2">
            <SignIn {...props} />
            <div className="columns p-3 mt-2 is-mobile">
              <div className="column login-nav box-shadow card rounded">
                <h2 className="subtitle is-6  has-text-centered has-text-white ">
                  Download The Clinical Rehab Version
                </h2>
                <div className="columns is-mobile">
                  <div className="column ">
                    <a
                      className="image is-128x128-custom ml-4"
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.sjinnovation.gameoffalls.live&hl=en&gl=US"
                    >
                      <img src={android} />
                    </a>
                  </div>
                  <div className="column">
                    <a
                      className="image is-128x128-custom is-pulled-right mr-4"
                      target="_blank"
                      href="https://apps.apple.com/us/app/game-of-falls/id1455203161"
                    >
                      <img src={ios} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InstructionsVideoModal
        isActive={isActive}
        toggleActive={handleModalActive}
        onVideoEnded={() => document.getElementById("Xmain").style.removeProperty('height')}
      />
    </HeaderFooterWraper>


  )

}
export default HomePage