import React, { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import brandImage from '../img/l.svg';
import config from '../../utils/config';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPowerOff, faX } from '@fortawesome/free-solid-svg-icons';

const DashboardHeader = () => {
  const GameId = '1';
  const [isActiveNav, setActiveNav] = useState(false);
  const generateReportAllGames = config.generateReportAllGames;
  let navigate = useNavigate()
  const toggleMenuBurger = () => {
    setActiveNav(!isActiveNav);
  }
 
  let user = JSON.parse(localStorage.getItem('user'));

  const handleLogOut = () => {
    localStorage.clear();
    return window.location.reload();
  }
  
  const generateReport = () => {
    // console.log(typeof generateReportAllGames);
    let token = localStorage.getItem('token');
    var config = {
      method: 'get',
      url: generateReportAllGames+GameId+'/1',
      headers: { 'Authorization': 'Bearer ' + token },
    };
    console.log("config",config.url);

    axios(config).then(function (response) {
      console.log("report:",response.data);
      window.open(response.data.report, "_blank");
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    });
  }

  return (
    <>
    <nav className="navbar  transparent-bg has-text-white" role="navigation" aria-label="main navigation">
      <div className="container">
      <div className="navbar-brand">
        <Link to="/dashboard"> 
          <figure className="image navbar-item navigation-links">
            <img src={brandImage} width="112" height="28"/>
          </figure>
        
        </Link>
        <a role="button" onClick={() => toggleMenuBurger() } className={`navbar-burger`} >
        {
          isActiveNav ? <FontAwesomeIcon icon={faX} /> : <FontAwesomeIcon icon={faBars} />
        }
        </a>
      </div>
      <div id="navbarBasicExample" className={`navbar-menu transparent-bg ${isActiveNav ? 'is-active' : ''} `}>
        <div className="navbar-end">
          <div className="navbar-item">
          <a href="/" className="button is-primary" onClick={() => { localStorage.clear()}}>
            <span className="icon">
            <FontAwesomeIcon icon={faPowerOff}></FontAwesomeIcon>
            </span>
            <span>Log Out</span>
          </a>
          {
            user && user.userType == "individualUser" ?
              <button className="button dashboard-button text-black white-bg" onClick={() => { navigate('/subscription')}}>
              <span>Subscription</span>
            </button>
            : null
          }
           <button className="button ml-2 white-bg dashboard-button text-black"  onClick={generateReport}>
              <span>Generate Report</span>
            </button>
         
          </div>
        </div>
      </div>
      </div>
    </nav>
    </>
    
  )

}

export default DashboardHeader;