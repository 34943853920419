import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';

import Confetti from 'react-confetti';

const PopUpTheEnd = ({isActive, showResults}) => {
  var height = window.innerHeight
  var width = window.innerWidth

  return (
    <>
      <div  className={`modal modal-fx-fadeInScale  ${isActive ? 'is-active' : ''}`}>
      <Confetti
      width={width}
      height={height}
      />
        <div className="modal-background"></div>
        <div className="modal-content ">
        <div className="card">
      <div className="card-content has-text-centered">
      <span className="icon-text">
        <span className="icon">
        <FontAwesomeIcon icon={faAward} className="has-text-success" />
        </span>
        <span>Congrats!!! You have Finished The Game.</span>
      </span>
        <span>
        <p></p>
        </span>
      </div>
    <footer className="card-footer">
      <a  className="card-footer-item has-background-success has-text-dark" onClick={showResults}>Show Results</a>
    </footer>
    </div>
      </div>
    </div>
    </>
  )
}

export default PopUpTheEnd;