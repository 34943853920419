import React from 'react';
import moment from 'moment';

const Footer = () => {
  return (
  <footer className="footer footer-bg">
    <div className="content has-text-centered">
      <small className="has-text-white pb-0 mb-1">
      <strong className="has-text-white is-size-7">Game of Falls</strong> - Powered by <a  href="https://www.2wayview.com/" target='_blank'> 2WayView</a>. 
      </small><br/>
      <small className="has-text-white has-text-grey-lighter is-size-7" >Copyright &copy; {moment().format('YYYY')} - IJMCM LLC - All Rights Reserved</small>
  </div>
  </footer>
  )
}
export default Footer ;