/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import ForgotPassword from './ForgotPassword';
import OnBoardingSignUpBox from './OnBoardingSignUpBox';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGooglePlus } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

const FacebookApId = config.facebook_app_id;
const GoogleClientId = config.google_client_id;

const GoogleSignInUrl = config.commonLoginWithGoogle;
const FacbeookSignInUrl = config.commonLoginWithFacebook;
const SignInUrl = config.commonLogin;



const SignIn = (props) => {

  const [isLoading, setLoading] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState();
  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });
  const [goToLogin, setGotoLogin] = useState(false)
  const [myEmail, setEmail] = useState()
  let url = '';
  let payload = {};
  let navigate = useNavigate();
  const onResponseGoogle = (res) => {

    if (!res.hasOwnProperty('error')) {
      setLoading(true);
      url = GoogleSignInUrl
      payload.idToken = res.tokenId
      HandleLogin()
    }
  }
  const OnFacebookResponse = (res) => {
    if (!res.hasOwnProperty('status')) {
      setLoading(true)
      url = FacbeookSignInUrl
      payload.userId = res.userID
      payload.accessToken = res.accessToken
      HandleLogin()
    }
  }
  const onSubmit = (data) => {
    setLoading(true)
    url = SignInUrl
    payload.email = data.email
    payload.password = data.password
    HandleLogin()
  }

  const HandleLogin = () => {
    var config = {
      method: 'post',
      url: url,
      headers: {},
      data: payload
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data.message,'handle login')
        if (response.data.message === "User is not registered.") {
          setLoading(false)
          // alert(response.data.message + ", Sign-up to continue")
          setErrorMessage(response.data.message + "Redirecting to SignUp... ")
          showErrorMessage()
          signUpToContinue()
          return
        }
        if (response.data.auth === false && response.data.message != "User is not registered.") {
          setLoading(false)
          setErrorMessage(response.data.message)
          return
        }

        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user ? response.data.user :{}));
        setLoading(false)
        navigate("/dashboard");
      })
      .catch(function (error) {
        // console.log(error)
        var err = error.response.data.message
        setErrorMessage(err)
        setLoading(false)
      });
  }

  const redirectToSignUp = () => {
    navigate('/');
  }

  const swipeLogin = () => {
    // setGotoLogin(true)
    //  console.log("heyyyyyyyyyy")
  }

  const swipeSignUp = () => {
    // if(goToLogin === true){
    // }
    setGotoLogin(true);
    // console.log('SignUp')
  }

  const signUpToContinue = () => {
    setTimeout(
      () => setGotoLogin(true),
      5000
    );
  }


  const showErrorMessage = () => {
    if (isErrorMessage) {
      return (
        <div className="message is-danger">
          <div className="message-body">{isErrorMessage}</div>
        </div>
      )
    }
  }


  return (
    <>
      {goToLogin ? <OnBoardingSignUpBox  {...props} /> :
        <>
          {
            goToLogin ? '' : <div className="card card-has-custom-width" >
              <header className="card-header white-bg">
                <div className="tabs fulltabs is-centered">
                  <ul className="has-text-centered">
                    <li className={`${goToLogin ? '' : 'is-active'} `} ><a className="login-nav has-text-white" onClick={swipeLogin}>Login</a></li>
                    <li className={`${goToLogin ? 'is-active' : ''} `}><a className="login-nav has-text-white" onClick={swipeSignUp}>Sign Up</a></li>
                  </ul>
                </div>
              </header>
              {/* <div className="">
                <p className="has-text-centered p-2">With</p>
              </div> */}
              <footer className="card-footer white-bg border-none">
                <FacebookLogin
                  appId={FacebookApId}
                  callback={OnFacebookResponse}
                  fields="name,email,picture"
                  render={renderProps => (
                    <a className="card-footer-item border-none has-facebook-bg " onClick={renderProps.onClick}>
                      <span className="icon has-text-white">
                      <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
                      </span>
                    </a>
                  )}
                />
                <GoogleLogin
                  clientId={GoogleClientId}
                  render={renderProps => (
                    <a className="card-footer-item google-bg has-text-white" onClick={() => renderProps.onClick()} >
                      <span className="icon">
                      <FontAwesomeIcon icon={faGooglePlus}></FontAwesomeIcon>
                      </span>
                    </a>
                  )}
                  autoLoad={false}
                  buttonText="Login"
                  onSuccess={onResponseGoogle}
                  onFailure={onResponseGoogle}
                />
              </footer>
            </div>
          }
          <div className="card has-sign-up white-bg rounded box-shadow">

            <div className="card-content ">

              {showErrorMessage()}

              <div className="field">
                <p className="control has-icons-left ">
                <input className={`input white-bg ${errors.email && 'is-danger'}`} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Username or Email" name="email" {...register('email', { required: 'Username or Email is required' })} />
                  <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                  </span>
                  {errors.email && <p className="help is-danger">{errors.email.message}</p>}
                </p>
              </div>
              <div className="field">
                <p className="control has-icons-left">

                  <input
                    className={`input white-bg ${errors.password && 'is-danger'}`}
                    type="password"
                    placeholder="Password"
                    name="password"
                    {...register('password', { required: 'Password is required' })}
                  />                  <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                  </span>
                  {errors.password && <p className="help is-danger">{errors.password.message}</p>}
                </p>
              </div>
              {/* <a className="helper has-text-info" onClick={redirectToSignUp}> Register here</a> */}
              <br />
              <ForgotPassword myEmail={myEmail} />
              <a className={` is-pulled-right is-info px-5 button  ${isLoading ? 'is-loading' : ''}`} onClick={handleSubmit(onSubmit)}>Login</a>
            </div>

          </div>
        </>
      }
    </>
  );
}

export default SignIn