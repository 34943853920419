import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../utils/config";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HeaderFooterWraper from "./HeaderFooterWrapper";
import Modal from "./Modal";
import SignUpInfoProfileBox from "./SignUpInfoProfileBox";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const GoogleSignUpUrl = config.individual_google_sign_up;
const FacbeookSignUpUrl = config.individual_facebook_sign_up;
const SignUpUrl = config.individual_sing_up;
const CalculatePriceWithDiscount = config.calculate_price_with_discount;
const IndividualSubscriptionPrice = config.indivudal_Subscription_price;

const SignUp = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [isErrorMessage, setErrorMessage] = useState(null);
  const [subscriptionMesssage, setSubscriptiponMessage] = useState(null);
  const [discountData, setDiscountData] = useState();
  const [discountLoading, setDiscountLoading] = useState(false);
  const [hideCardForm, setHideCardForm] = useState(false);
  const [price, setPrice] = useState(null);
  const [isDisccountError, setDiscountError] = useState();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  let url;
  let payload = {};

  const OnModalToggle = () => {
    setModalOpen(!isModalOpen);
  };

  const goToHomeScreen = () => {
    navigate("/");
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => fetchDiscountCalculation(discountCode),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [discountCode]);

  useEffect(() => {
    fetchPrice();
  }, []);

  const fetchPrice = () => {
    const config = {
      method: "get",
      url: IndividualSubscriptionPrice,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        setPrice(response.data.price);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const fetchDiscountCalculation = (discountCode) => {
    setDiscountLoading(true);
    const config = {
      method: "post",
      url: CalculatePriceWithDiscount,
      headers: {},
      data: { discountCode },
    };
    axios(config)
      .then(function (response) {
        setDiscountError(null);
        setDiscountData(response.data);
        setDiscountLoading(false);
        if (response.data.discountPercentage === 100) {
          setHideCardForm(true);
        }
      })
      .catch(function (error) {
        setDiscountError(error.response.data.message);
        setDiscountData();
        setDiscountLoading(false);
      });
  };

  const onSubmit = (paymentMethodId) => {
    setLoading(true);
    
    payload.paymentMethodId = paymentMethodId == "" ? null : paymentMethodId;

    if (discountCode) {
      payload.discountCode = discountCode;
    }

    const config = {
      method: "post",
      url,
      headers: {},
      data: payload,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        setSubscriptiponMessage(response.data);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user || {}));
        setModalOpen(true);
      })
      .catch(function (error) {
        setErrorMessage(
          error.response.data.message +
            " Please login to continue. Redirecting to login..."
        );
        setLoading(false);
        setTimeout(() => navigate("/"), 4000);
      });
  };

  const getComponentBasedOnForm = () => {
    if (state.isSocialLogin) {
      if (state.social_data.signUpMethod === "facebook") {
        const { name, email } = state.social_data;
        const imageUrl = state.social_data.picture.data.url;
        url = FacbeookSignUpUrl;
        payload.userId = state.social_data.userID;
        payload.accessToken = state.social_data.accessToken;
        return (
          <>
            <SignUpInfoProfileBox src={imageUrl} email={email} name={name} />
            <PaymentForm
              price={price}
              discountData={discountData}
              discountLoading={discountLoading}
              discountCode={discountCode}
              setDiscountCode={setDiscountCode}
              hideCardForm={hideCardForm}
              isDisccountError={isDisccountError}
              email={email}
              payload={payload}
              isLoading={isLoading}
              onSubmit={onSubmit}
            />
          </>
        );
      } else {
        const { name, email, imageUrl } = state.social_data.profileObj;
        url = GoogleSignUpUrl;
        payload.idToken = state.social_data.tokenId;
        return (
          <>
            <SignUpInfoProfileBox src={imageUrl} email={email} name={name} />
            <PaymentForm
              price={price}
              discountData={discountData}
              discountLoading={discountLoading}
              discountCode={discountCode}
              setDiscountCode={setDiscountCode}
              hideCardForm={hideCardForm}
              isDisccountError={isDisccountError}
              email={email}
              payload={payload}
              isLoading={isLoading}
              onSubmit={onSubmit}
            />
          </>
        );
      }
    } else {
      const { password, email, username } = state.form_data;
      url = SignUpUrl;
      payload.userName = username;
      payload.email = email;
      payload.password = password;
      return (
        <>
          {email !== "" && (
            <fieldset>
              <div className="field-body">
                <div className="field">
                  <label style={{ color: 'gray'}} className="label">Email</label>
                  <div className="control">
                    <input
                      className="input text-black bg-gray-200 dark:text-white dark:bg-gray-800 border border-gray-400"
                      type="text"
                      placeholder=""
                      value={email}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </fieldset>
          )}
          <PaymentForm
            price={price}
            discountData={discountData}
            discountLoading={discountLoading}
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            hideCardForm={hideCardForm}
            isDisccountError={isDisccountError}
            email={email}
            payload={payload}
            isLoading={isLoading}
            onSubmit={onSubmit}
          />
        </>
      );
    }
  };

  const showErrorMessage = () => {
    if (isErrorMessage) {
      return (
        <div className="message is-danger">
          <div className="message-body">{isErrorMessage}</div>
        </div>
      );
    }
  };

  return (
    <HeaderFooterWraper>
      <Elements stripe={stripePromise}>
        <div className="columns">
          <div className="column">
            <div className="container">
              <div style={{ backgroundColor: 'white', padding: 20}}>
                <header className="card-header">
                  <h1 style={{ fontSize: 26, color: 'black' }} className="card-header-title pb-5">Billing</h1>
                  <div className="card-header-icon" onClick={goToHomeScreen}>
                    <span className="icon">
                      <text>X</text>
                    </span>
                  </div>
                </header>
                <div className="card-content">
                  {isModalOpen && (
                    <Modal
                      isActive={isModalOpen}
                      title={"Payment Info"}
                      OnModalToggle={OnModalToggle}
                      data={subscriptionMesssage}
                    />
                  )}
                  {showErrorMessage()}
                  {getComponentBasedOnForm()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Elements>
    </HeaderFooterWraper>
  );
};

// PaymentForm component defined outside of SignUp but in the same file
const PaymentForm = ({
  price,
  discountData,
  discountLoading,
  discountCode,
  setDiscountCode,
  hideCardForm,
  isDisccountError,
  email,
  payload,
  isLoading,
  onSubmit
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const submit = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (!hideCardForm) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      if (error) {
        alert(error.message);
        return;
      }
  
      onSubmit(paymentMethod.id)
    }
    else{
      onSubmit("");
    }
  }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "black",
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        lineHeight: "24px",
        "::placeholder": {
          color: "grey",
        },
        backgroundColor: "white",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <br />
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <p
              className={`control has-icons-left ${
                discountLoading ? "is-loading" : ""
              }`}
            >
              <input
                className="input text-black"
                type="text"
                placeholder="Discount Code"
                onChange={(e) => setDiscountCode(e.target.value)}
                value={discountCode}
                disabled={discountLoading}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-percentage"></i>
              </span>
              {isDisccountError && (
                <p className="help is-danger">{isDisccountError}</p>
              )}
            </p>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input text-black"
                type="text"
                value={`Total Amount : $ ${
                  discountData ? discountData.totalPrice : price + ".00"
                }`}
                readOnly
                disabled
              />
              {discountData && (
                <p className="help is-success has-text-weight-bold">
                  You got {discountData.discountPercentage}% discount
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Conditional card form */}
      {!hideCardForm && (
        <div className="mt-5">
          <div className="mb-2">Card Details</div>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
      )}
      <div className="m-5"></div>
      <footer className="card-footer p-4">
        <button
          type="button"
          className={`card-footer-item is-primary button is-ghost ${
            isLoading ? "is-loading" : ""
          }`}
          onClick={submit}
        >
          Complete Sign Up
        </button>
      </footer>
    </>
  );
};

export default SignUp;
