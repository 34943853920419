import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from '../AboutUs';
import Dashboard from '../dashboard/Dashboard';
import GameInterface from '../dashboard/GameInterface';
import FacilityUserLogin from '../facilityUser/FacilityUserLogin';
import FacilityUserResetPassword from '../facilityUser/FacilityUserResetPassword';
import FacilityUserSignUp from '../facilityUser/FacilityUserSignUp';
import SignUp from '../helpers/SignUp';
import Forbidden from '../helpers/Forbidden';
import ResetPassword from '../helpers/ResetPassword';
import SignIn from "../helpers/SignIn";
import HomePage from '../HomePage';
import IndividualUserLogin from '../individualUser/IndividualUserLogin';
import IndividualUserResetPassword from '../individualUser/IndividualUserResetPassword';
import IndividualUserSignUp from '../individualUser/IndividualUserSignUp';
import PirvacyPolicy from '../PrivecyPolicy';
import Terms from '../Terms';
import PrivacyPolicyApp from '../PrivacyPolicyApp';
import TermsApp from '../TermsApp';
import ProtectedRoute from '../routes/ProtectedRoute';
import PublicRoute from '../routes/PublicRoute';
import Subscription from "../dashboard/Subscription";
import ContactUs from "../ContactUs";

const RoutHandler = () => {
  return (
    <Router>
      <Routes>
        <Route path='/Dashboard' element={<Dashboard />} />
        <Route path='/subscription' element={<Subscription />} />
        <Route path="/sign-in" element={<PublicRoute restricted={true} component={SignIn} />} />
        <Route path="/PirvacyPolicy" element={<PirvacyPolicy />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/PrivacyPolicyApp" element={<PrivacyPolicyApp />} />
        <Route path="/TermsApp" element={<TermsApp />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/forgotPassword" element={<ResetPassword />} />
        <Route path='/gameInterface' element={<GameInterface />} />
        <Route path="/facilityUserlogin" element={<FacilityUserLogin />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/facilityUserSignup" element={<FacilityUserSignUp />} />
        <Route path="/facilityUserForgotPassword" element={<FacilityUserResetPassword />} />
        <Route path="/individualUserForgotPassword" element={<IndividualUserResetPassword />} />
        <Route path="/individualUserLogin" element={<IndividualUserLogin />} />
        <Route path="/individualUserSignup" element={<IndividualUserSignUp />} />
        <Route path="/forbidden" element={<Forbidden />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
    </Router>
  )
}
export default RoutHandler;