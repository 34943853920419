import ReactPlayer from 'react-player';
import config from '../../utils/config';

const videoUrl = config.right_you_got_it;
const CorrectModal_01 = ({isActive, onEndedCorrectModal_01, handleContinue, handlePause}) => {

  return (
    <div className={`modal is-clipped ${isActive ? 'is-active' : ''}`}>
      <div   className="modal-background"></div>
      <div    className="modal-content">
        <div className="card">
        <div className="card-content">
        <ReactPlayer 
          width="100%"
          height="100%"
          playing={isActive}
          url={videoUrl}
          onEnded={onEndedCorrectModal_01}
        />
        </div>
         
        </div>
      </div>
      
     
    </div>
  )
}

export default CorrectModal_01;