/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { useEffect, useState } from "react";
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import config from '../../utils/config';
import DashboardFooter from '../helpers/DashboardFooter';
import DashboardHeader from '../helpers/DashboardHeader';
import Report from './Report';
import Result from "./Results";
import ResumeGameBoard from './ResumeGameBoard';
import FeedbackModal from '../helpers/FeedbackModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

const getAnswerUrl = config.getAllAnswersForAllGames;
const getAllAnswersForAllGames = config.getAllAnswersForAllGamesNew;
const ResumeGame = config.getResumeGame;
const FeedbackURL = config.giveFeedback;
const GameId = '1';

const DashBoardWrapper = (props) => {
  //resume
  const navigate = useNavigate();
  const location =useLocation();
  const [resumeData, setResumeData] = useState();
  const [isResumeLoading, setResumeLoading] = useState(false)
  const [isFeedbackPopUpActive, setFeedbackPopUpActive] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isShowMessage, setShowMessage] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false)

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const showMessage = (response) => {

    if (isSuccess) {
      return (
        <div className="message is-success">
          <div className="message-body">{response}</div>
        </div>
      )
    } else if (isError) {
      return (
        <div className="message is-danger">
          <div className="message-body">{response}</div>
        </div>
      )
    }

  }

  useEffect(() => {
    getResumeGame()
  }, [])

  const getResumeGame = () => {
    setResumeLoading(true)
    const url = ResumeGame + GameId;
    var config = {
      method: 'get',
      url: url,
      headers: { 'Authorization': 'Bearer ' + token },
    };
    axios(config)
      .then(function (response) {
        setResumeData(response.data);
        setResumeLoading(false)
        // console.log(response.data, 'data final')
      })
      .catch(function (error) {
        setResumeLoading(false)
        //fail silently
      });
  }
  const handleFeedbackSubmit = (e) => {
    // console.log('user email', user.email);
    e.preventDefault();
    setLoading(true)

    axios({
      method: "POST",
      url: FeedbackURL,
      data: { feedback: feedback, email: user.email, submittedOn: new Date(Date.now()) }
    }).then((response) => {
      if (response.status === 200) {
        setLoading(false)
        // console.log(response.data.message)
        setShowMessage(response.data.message)
        setSuccess(true)
        showMessage(response.data.message)
        setFeedbackPopUpActive(false)
        setFeedback("");
        return

      } else if (response.status !== 200) {
        setLoading(false)
        // console.log(response.data.message);
        setShowMessage(response.data.message)
        setError(true)
        showMessage(response.data.message)
        setFeedbackPopUpActive(false)
        setFeedback("");
      }
    }).catch((error) => {
      // console.log(error);
      setLoading(false)
      setShowMessage(error.message)
      setError(true)
      showMessage(error.message);
      setFeedbackPopUpActive(false)
      setFeedback("");
    })

  }

  const handleResume = () => {
    // console.log(resumeData.lastAnsweredExercise)
    navigate('/gameInterface', {
      state: {
        GameId,
        lastExercise: resumeData.lastAnsweredExercise,
        resume: true
      }
    })
  }
  const handleNewGame = () => {
    navigate('/gameInterface', {
      state: {
        GameId,
        resume: false,
        newSession: true
      }
    })
  }


  //resume end

  const tabList = [
    {
      name: "Games",
      icon: "gamepad",
      content: <ResumeGameBoard {...props} />
    }, {
      name: "Reports",
      icon: "file-alt",
      content: <Result />
    }
  ];
  const [activeTab, setActiveTab] = useState('Games');
  const [toggleNav, setToggleNav] = useState(false);

  const handleToggle = () => {
    setToggleNav(!toggleNav);
  }

  const handleLogOut = () => {
    setLoading(true)
    localStorage.clear();
    setLoading(false)
    window.location.reload();
  }

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  }

  const activeGame = () => {
    setActiveTab('Games')
  }
  const activeReport = () => {
    setActiveTab('Reports')
  }


  useEffect(() => {
    if (location.state === 'game') {
      changeActiveTab('Games')
    }
  }, [location]);


  const activeTabContent = () => {
    const activeIndex = tabList.findIndex((tab) => {
      return tab.name === activeTab;
    });
    return tabList[activeIndex].content;
  }

  const onFeedbackInput = (event) => {
    setFeedback(event.target.value);
  }

useEffect(() => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = () => {
    window.history.pushState(null, "", window.location.href);
  };
}, []);


  return (
    !user ? <Navigate to="/" /> :
      user && user.userType === "individualUser" && !user.isSubscribed ? <Navigate to="/subscription" /> :
        <div className="main" style={{ overflowY: 'hidden', overflowX: 'hidden', position: 'static', height: 'auto' }}>
          <DashboardHeader />
          <main className="main-content-dashboard px-2">
            <div className="columns has-padding-mobile">
              <div className="column is-three-fifths is-offset-one-fifth mt-2">
                <div className="container">
                  
                  <div className="card card-has-shadow white-bg">
                    <header className="card-header card-has-shadow ">
                      <div className="tabs-dashboard box-shadow fulltabs is-centered">
                        <ul className="has-text-centered has-background-info">
                          <li className={`${activeTab === 'Games' ? '' : 'is-active'} `}><a className="login-nav has-text-white" onClick={activeGame}>Game</a></li>
                          <li className={`${activeTab != 'Games' ? 'is-active' : ''} `} ><a className="primary-bg has-text-white" onClick={activeReport}>Reports</a></li>
                        </ul>
                      </div>
                    </header>
                    <div className="card-content white-bg">
                      <div className="content">
                        {
                          activeTab === 'Games' ? <ResumeGameBoard /> : <Report resumeData={resumeData} />
                        }
                      </div>
                      <FeedbackModal
                        isActive={isFeedbackPopUpActive}
                        handleCancel={() => {
                          setFeedbackPopUpActive(false)
                        }}
                        onFeedbackInput={onFeedbackInput}
                        handleFeedbackSubmit={handleFeedbackSubmit}
                        feedbackValue={feedback}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer className="footer-dashboard is-fixed-bottom">
            <div
              className="level-item is-size-7 has-text-darkgrey pulsating is-clickable"
              onClick={() => setFeedbackPopUpActive(true)}
            >GIVE A FEEDBACK</div>
          </footer>
          <DashboardFooter />
        </div>
  )
}
export default DashBoardWrapper
