import moment from 'moment';
import { useNavigate } from "react-router-dom";

const Modal = ({isActive, title, OnModalToggle, data}) => {
  const d =  moment(data.user.subscriptionEndDate).format('dddd, MMMM Do YYYY');
  
  let navigate = useNavigate();
  const OnClick = () => {
    navigate('/dashboard');
  } 
  return (
    <>
    <div className={`modal  ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card white-bg rounded">
        <header className="modal-card-head white-bg rounded">
          <p className="modal-card-title text-black">{title}</p>
        </header>
        <section className="modal-card-body white-bg rounded">
        <div className="block is-primary">
        <text className='text-black'>Thank you for your payment of ${data.user.amountCharge}. Your current subscription ends on {d}</text>
        </div>

        </section>
        <footer className="modal-card-foot white-bg rounded">
          <button  className="button is-success" onClick={OnClick}>Okay</button>
        </footer>
      </div>
    </div>
    </>
  )
}

export default Modal


