import React from 'react';

const FeedbackModal = ({ isActive, handleCancel, onFeedbackInput, handleFeedbackSubmit, feedbackValue }) => {
    return (
        <div className={`modal modal-fx-fadeInScale is-clipped ${isActive ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className="card white-bg rounded">
                    <div className="card-content  has-text-centered">
                        <h1 className="text-black is-size-5 has-text-default"> Is there anything you would like to add about your experience with the "Game of Falls"?</h1>
                        <textarea placeholder="Enter your feedback here..." name="feedback" id="message" rows="5" className="textarea rounded white-bg text-black" onChange={onFeedbackInput} value={feedbackValue} required></textarea>
                    </div>
                    <footer className="card-footer border-none">
                        <p className=" card-footer-item border-none is-danger navbar-menu has-text-dark is-clickable" onClick={handleFeedbackSubmit}>Submit Feedback</p>
                        <p className=" card-footer-item border-none is-warning warning-bg has-text-dark is-clickable" onClick={handleCancel}>Cancel</p>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default FeedbackModal;