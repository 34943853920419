import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import config from '../../utils/config';
import HeaderFooterWrapper from '../helpers/HeaderFooterWrapper';
import Panel from '../helpers/Panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEnvelope, faHospitalAlt, faSignOut } from '@fortawesome/free-solid-svg-icons';

const signUpUrl = config.facility_sign_up;

const FacilityUserLogin = () => {
    const BaseUrl = window.location.href.split('/')[2];
  
    const [ isLoading, setLoading ] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({mode : 'onChange'});    
    const [isErrorMessage, setErrorMessage] = useState();

    const onSubmit = (data) => { 
      setLoading(true);
    
      var config = {
        method: 'post',
        url: signUpUrl,
        headers: {},
        data: data
      };
    
      axios(config)
        .then((response) => {
          setLoading(false);
    
          // Redirect only if the request is successful (status 200 or 201)
          if (response.status === 200 || response.status === 201) {
            window.location.replace('http://' + BaseUrl + '/facilityUserLogin');
          } else {
            setErrorMessage("Unexpected response. Please try again.");
          }
        })
        .catch((error) => {
          setLoading(false);
    
          // Handle 422 User Exists Error
          if (error.response?.status === 422) {
            setErrorMessage(error.response.data.message || "User already exists.");
          } else {
            // Handle other errors
            setErrorMessage(error.response?.data?.message || "An error occurred. Please try again.");
          }
        });
    };
    
    
    const renderContent = () => {
      if(isErrorMessage){
        return(
          <div    className="message is-danger">
            <div    className="message-body">{isErrorMessage}</div>
          </div>
        )
      }
    }
    return (
      <HeaderFooterWrapper>
        <div className="columns  py-3">
          <div className="column is-three-fifths is-offset-one-fifth  mt-2">
              <Panel 
                title="Sign Up"
                subTitle="Login"
                subTitleLink="/facilityUserLogin"
              >
              {renderContent()}
                <form  onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                  <p className="control has-icons-left">
                  <input className={`input ${errors.email && 'is-danger'}`} type="email" placeholder="Email" {...register("email", {required: "Email Required"})}/>                    
                    <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                    </span>
                    {errors.email && <p    className="help is-danger">{errors.email.message}</p>}
                  </p>
                </div>
                <div className="field">
                  <p className="control has-icons-left">
                  <input className={`input ${errors.password && 'is-danger'}`} type="password" placeholder="Password" {...register("password", {required : "Pssword Required", minLength : {value : 8, message : 'Password too short minimum 8 digit'}})}/>                    
                    <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                    </span>
                    {errors.password && <p    className="help is-danger">{errors.password.message}</p>}
                  </p>
                </div>
                <div className="field">
                  <p className="control has-icons-left">
                  <input className="input" type="text" placeholder="Facility Code" {...register("facilityCode")}/>                    
                    <span className="icon is-small is-left">
                    <FontAwesomeIcon icon={faHospitalAlt}></FontAwesomeIcon>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control">
                    <button className={`button is-success ${isLoading ? 'is-loading' : ''} is-pulled-right`} type="submit" >Login</button>
                  </p>
                </div>
            </form>
          </Panel>
        </div>
      </div>
    </HeaderFooterWrapper>
  )
}

export default  FacilityUserLogin ;