/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import axios from "axios";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Modal, Backdrop, makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import config from "../../utils/config";
import SurveyForm from "./SurveyForm";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
}));

const PopulateSurvey = ({ activeExercise }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeExerciseTrack, setActiveExerciseTrack] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [surveyList, setSurveyList] = React.useState([]);
  const [answers, setAnswers] = useState([]);
  const [data, setData] = useState([]);
  const [isPlaying, setPlaying] = useState(false);
  const [userAnsweredSurvey, setUserAnsweredSurvey] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let token = localStorage.getItem("token");
  let user = JSON.parse(localStorage.getItem('user'));

  const UserAllSurvey = config.getSurveyForAllUser;
  const AnswerSurveyUrl =
    config.answerQuestionOfSurvey + "/" + surveyList[currentQuestion]?.surveyID;
  const style = {
    position: "relative",
    overflowY: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  function checkUserResponse(userId, surveys) {
    for (let survey of surveys) {
      const response = survey.responses.find((response) => {
        return response.userId === userId;
      });
      if (response) {
        // console.log('Returening True:', response);
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (activeExercise === 4 && activeExerciseTrack === true) {
      if (!userAnsweredSurvey) {
        setOpen(true);
        setPlaying(false);
      }
    }

    const url = UserAllSurvey;
    var config = {
      method: "get",
      url: url,
      headers: { Authorization: "Bearer " + token },
    };
    axios(config)
      .then(function (response) {
        const userHasResponse = checkUserResponse(user.userId, response.data);
        // console.log(userHasResponse, "back here");
        setSurveyList(response.data);

        if (userHasResponse) {
          setUserAnsweredSurvey(true)
        } else {
          setUserAnsweredSurvey(false)
        }
      })
      .catch(function (error) {
        setSurveyList([]);
      });
  }, [
    surveyList.length === 0 || (activeExercise === 4 && activeExerciseTrack === true),
  ]);

  const handleAnswerResponse = () => {
    setScore(score + 1);

    var config = {
      method: "post",
      url: AnswerSurveyUrl,
      headers: { Authorization: "Bearer " + token },
      data: data,
    };

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < surveyList?.length) {
      setCurrentQuestion(nextQuestion);
      setData([]);
      setAnswers([]);
    } else {
      setShowScore(true);
    }

    axios(config)
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        const err = error.response.data.errors;
        // console.log(err);
      });
  };

  const resetQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div style={{ marginBottom: "128px" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          classes: { root: classes.customBackdrop },
          onClick: handleBackdropClick,
        }}
      >
        <Box sx={style}>
          <div className="app">
            {showScore ? (
              <div
                className="has-text-primary"
                style={{
                  textAlign: "center",
                }}
              >
                Thanks for Answering the Survey
                <br />
                <br />
                <>
                  <Button
                    type="submit"
                    onClick={() => {
                      resetQuiz();
                      setOpen(false);
                      setPlaying(true);
                      setActiveExerciseTrack(false);
                    }}
                  >
                    Finish the Survey!!
                  </Button>
                </>
              </div>
            ) : (
              <>
                <div className="question-section">
                  <div className="card-header-title">
                    {surveyList[currentQuestion]?.title}
                  </div>
                </div>
                <br />
                <SurveyForm
                  questions={surveyList[currentQuestion]?.questions}
                  setAnswers={setAnswers}
                  answers={answers}
                  setSurveyList={setSurveyList}
                  setData={setData}
                />
                <br />
                <div>
                  <Button
                    variant="contained"
                    onClick={() => handleAnswerResponse()}
                    disabled={
                      surveyList[currentQuestion]?.questions?.length !==
                      answers?.length
                    }
                  >
                    Next
                  </Button>
                </div>
                <br />
              </>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PopulateSurvey;
