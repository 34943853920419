import React from "react";
import {
  Navigate, Route
} from "react-router-dom";

const PublicRoute = ({component: Component, restricted, ...rest}) => {
  
  const token = localStorage.getItem('token');
  
  return  (
    <Route 
      {...rest}
      element={
        token && restricted ? <Navigate to="/dashboard" /> : <Component {...rest} /> 
      }
    />
  )
}

export default PublicRoute;